import { WnaDiaryEntryPlaceholders } from "@domain/contracts/types/WnaDiaryEntryPlaceholder";
import WnaAppSettings from "@domain/entities/WnaAppSettings";
import WnaAsyncFileCacheProvider from "@infrastructure/services/storage/WnaAsyncFileCacheProvider";
import WnaAsyncStorageProvider from "@infrastructure/services/storage/WnaAsyncStorageProvider/WnaAsyncStorageProvider";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";
import getAppSettingsAsync from "@infrastructure/wnaApi/appSettings/GetAppSettings";
import {
    getResourceBase64Async,
    getResourceTextAsync,
} from "@infrastructure/wnaApi/resource/GetResource";
import base64 from "react-native-base64";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";
import WnaLogger from "wna-logger";

const _currentAppThemeStorage = "currentAppTheme";
const _useDb = false;

export default class WnaAppSettingsDao {
    public static async getCurrentAppThemeIdAsync() {
        try {
            const storageAppThemeId =
                (await WnaAsyncStorageProvider.getItemAsync(
                    _currentAppThemeStorage,
                    false,
                    _useDb
                )) ?? "0";
            WnaLogger.info(
                WnaAppSettingsDao.name,
                WnaAppSettingsDao.getCurrentAppThemeIdAsync.name,
                "storageAppThemeId: " + storageAppThemeId
            );
            const appThemeId = parseInt(storageAppThemeId);
            if (Number.isNaN(appThemeId)) return 0;
            return appThemeId;
        } catch {
            return 0;
        }
    }

    public static async setCurrentAppThemeIdAsync(appThemeId: Int32) {
        if (!Number.isInteger(appThemeId) || Number.isNaN(appThemeId))
            appThemeId = 0;
        WnaLogger.info(
            WnaAppSettingsDao.name,
            WnaAppSettingsDao.setCurrentAppThemeIdAsync.name,
            "appThemeId: " + appThemeId
        );
        return await WnaAsyncStorageProvider.setItemAsync(
            _currentAppThemeStorage,
            appThemeId.toString(),
            false,
            _useDb
        );
    }

    public static getImageResourceUrl(filename: string) {
        return `${WnaApiSettings.apiBaseUrl}/resources/${filename}`;
    }

    public static async getResourceAsync(fileName: string) {
        const fileExt = fileName.split(".").pop();
        const fileExtLow = fileExt!.toLocaleLowerCase();
        switch (fileExtLow) {
            case "html":
            case "json":
                return await getResourceTextAsync(fileName);
            case "pdf":
                return await getResourceBase64Async(fileName);
            default:
                return await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                    `${WnaApiSettings.apiBaseUrl}/resources/${fileName}`,
                    fileExtLow
                );
        }
    }

    public static async getAppSettingsAsync(fallback?: WnaAppSettings) {
        let appSettings = await getAppSettingsAsync();
        const storage = "appSettings";
        const encrypt = true;
        const useDb = false;
        if (appSettings.googleApiKey !== "") {
            await WnaAsyncStorageProvider.setItemAsync(
                storage,
                JSON.stringify(appSettings),
                encrypt,
                useDb
            );
            return appSettings;
        } else {
            const localAppSettingsJson =
                (await WnaAsyncStorageProvider.getItemAsync(
                    storage,
                    encrypt,
                    useDb
                )) ?? "";
            if (localAppSettingsJson !== "")
                appSettings = JSON.parse(localAppSettingsJson);
        }
        return appSettings.googleApiKey === ""
            ? fallback
                ? fallback
                : new WnaAppSettings()
            : appSettings;
    }

    public static async getDiaryEntryPlaceholdersAsync() {
        const placeholdersJson = await this.readResourceAsync(
            "appSettingsDiaryPlaceholders.json"
        );
        return (
            placeholdersJson === "" ? {} : JSON.parse(placeholdersJson)
        ) as WnaDiaryEntryPlaceholders;
    }

    public static async readResourceAsync(
        fileName:
            | "privacy.html"
            | "privacy-de.html"
            | "privacy-en.html"
            | "disclaimer.html"
            | "disclaimer-de.html"
            | "disclaimer-en.html"
            | "accommodation.html"
            | "accommodation-de.html"
            | "accommodation-en.html"
            | "day-schedule.html"
            | "day-schedule-de.html"
            | "day-schedule-en.html"
            | "appSettingsDiaryPlaceholders.json"
    ) {
        const storage = fileName.split(".")[0];
        let encrypt: boolean;
        switch (fileName) {
            default:
                encrypt = false;
                break;
        }

        let b64 = await getResourceBase64Async(fileName);
        if (b64 === "") {
            WnaLogger.warn(
                WnaAppSettingsDao.name,
                this.readResourceAsync.name,
                `failed to read resource '${fileName}'`
            );
            b64 =
                (await WnaAsyncStorageProvider.getItemAsync(
                    storage,
                    encrypt,
                    _useDb
                )) ?? "";
        }

        if (b64 !== "") {
            await WnaAsyncStorageProvider.setItemAsync(
                storage,
                b64,
                encrypt,
                _useDb
            );
            return base64.decode(b64);
        }

        return "";
    }
}
