import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import WnaDialogProvider from "@app/dialog/WnaDialogProvider";
import WnaToastProvider from "@app/toast/WnaToastProvider";
import WnaUser from "@domain/entities/WnaUser";
import WnaDiaryEntryDao from "@infrastructure/dao/WnaDiaryEntryDao";
import WnaUserDao from "@infrastructure/dao/WnaUserDao";
import WnaFirebase from "@infrastructure/firebase/WnaFirebase";
import { i18nKeys } from "@infrastructure/i18n/i18nKeys";
import WnaAsyncFileCacheProvider from "@infrastructure/services/storage/WnaAsyncFileCacheProvider";
import WnaAsyncStorageProvider from "@infrastructure/services/storage/WnaAsyncStorageProvider/WnaAsyncStorageProvider";
import WnaNavigationProvider from "@navigation/WnaNavigationProvider";
import WnaButtonText from "@ui/components/buttons/WnaButtonText";
import WnaImage from "@ui/components/images/WnaImage";
import WnaPicker, { WnaDataSourceItem } from "@ui/components/misc/WnaPicker";
import WnaSeparatorHorizontal from "@ui/components/misc/WnaSeparatorHorizontal";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, Text, View } from "react-native";
import WnaLogger from "wna-logger";

const WnaProfileScreen: React.FC<WnaStackScreenProps> = (props) => {
    const {
        currentAppTheme,
        currentAppStyle,
        currentUser,
        currentAdminUser,
        currentUserSettings,
        setCurrentUser,
        setCurrentAdminUser,
        isInternetReachable,
    } = useContext(WnaAppContext);
    // const [isBusy, setIsBusy] = useState(false);
    // const [isBusyText, setIsBusyText] = useState("");
    const { t } = useTranslation(["common"]);

    const questionLogOut = () => {
        if (isInternetReachable == false) {
            WnaToastProvider.showError(t(i18nKeys.errorNoInternet));
            return;
        }

        WnaDialogProvider.showYesNo(t(i18nKeys.questionLogOut), async () => {
            await WnaFirebase.signOutAsync();
            await WnaAsyncStorageProvider.clearAsync();
            WnaDiaryEntryDao.resetIsSynced();
            setCurrentUser(new WnaUser());
            setCurrentAdminUser(new WnaUser());
            props.navigation.popToTop();
            WnaNavigationProvider.navigateToStartPageScreen();
            setCurrentUser(new WnaUser());
        });
    };

    const questionLogOutAndDeleteAll = () => {
        if (isInternetReachable == false) {
            WnaToastProvider.showError(t(i18nKeys.errorNoInternet));
            return;
        }

        WnaDialogProvider.showYesNo(
            t(t(i18nKeys.questionLogOutAndDeleteData)),
            async () => {
                await WnaFirebase.signOutAndDeleteDataAsync();
                setCurrentUser(new WnaUser());
                setCurrentAdminUser(new WnaUser());
                props.navigation.popToTop();
                WnaNavigationProvider.navigateToStartPageScreen();
            }
        );
    };

    const usersDsRawRef = useRef<Array<WnaUser>>([]);
    const [usersDs, setUsersDs] = useState<Array<WnaDataSourceItem>>([]);
    const [selectedUserDsVal, setSelectedUserDsVal] = useState<any>();

    const setSelectedUserAsync = async (selectedUser: WnaUser) => {
        if (selectedUser.id == currentUser?.id) return;

        WnaUserDao.disableCreateOrUpdate();
        selectedUser.pictureBase64 =
            await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                selectedUser.picture,
                "png"
            );
        setCurrentUser(selectedUser);
        WnaToastProvider.showSuccess(selectedUser.email);
    };

    const loadUsersAsync = async () => {
        WnaLogger.start(WnaProfileScreen.name, loadUsersAsync.name);
        try {
            if (currentAdminUser == null || currentAdminUser.id === "") return;

            usersDsRawRef.current = await WnaUserDao.readAllAsync();
            setUsersDs(
                usersDsRawRef.current.map((item) => {
                    return {
                        value: item.identifier,
                        label: item.email,
                    } as WnaDataSourceItem;
                })
            );
            const selectedUser = usersDsRawRef.current.find(
                (x) => x.identifier === currentUser?.identifier
            );
            if (selectedUser) setSelectedUserDsVal(selectedUser.id);
        } catch (error) {
            WnaLogger.error(WnaProfileScreen.name, loadUsersAsync.name, error);
        }
        WnaLogger.end(WnaProfileScreen.name, loadUsersAsync.name);
    };

    if (currentUser === null) return null;

    useEffect(() => {
        loadUsersAsync();
    }, []);

    return (
        <WnaBaseScreenWithInfo>
            <ScrollView style={[{ padding: 16 }]}>
                <View
                    style={[
                        currentAppStyle.containerCenterMaxWidth,
                        {
                            padding: 24,
                            backgroundColor: currentAppTheme.colors.white,
                        },
                    ]}>
                    {/*Admin*/}
                    {currentAdminUser !== null && currentAdminUser.id !== "" ? (
                        <View
                            style={[
                                currentAppStyle.containerCenterMaxWidth,
                                { zIndex: 2000 },
                            ]}>
                            {/* switch user */}
                            <WnaPicker
                                currentAppStyle={currentAppStyle}
                                currentAppTheme={currentAppTheme}
                                dataSource={usersDs}
                                selectedKey={selectedUserDsVal}
                                onSelectionChanged={(val) => {
                                    if (val == null) return;

                                    const selectedUser =
                                        usersDsRawRef.current.find(
                                            (x) => x.identifier === val
                                        );
                                    if (selectedUser != undefined) {
                                        setSelectedUserDsVal(
                                            selectedUser.identifier
                                        );
                                        setSelectedUserAsync(selectedUser);
                                    }
                                }}
                            />
                        </View>
                    ) : null}
                    <View
                        style={[currentAppStyle.containerCenter, { gap: 16 }]}>
                        <WnaImage
                            imageUrl={currentUser?.pictureBase64}
                            currentAppTheme={currentAppTheme}
                            style={{
                                width: 96,
                                height: 96,
                                borderRadius: 48,
                                borderColor: currentAppTheme.colors.white,
                                borderWidth: 2,
                            }}
                        />
                        <Text
                            style={[
                                currentAppStyle.textTitleLarge,
                                {
                                    marginTop: 16,
                                    color: currentAppTheme.colors.coolgray6,
                                    maxWidth: 256,
                                },
                            ]}
                            numberOfLines={1}
                            ellipsizeMode="tail">
                            {currentUser?.name}
                        </Text>
                    </View>
                    <View
                        style={[currentAppStyle.containerCenter, { gap: 16 }]}>
                        <WnaSeparatorHorizontal />

                        {/* logout */}
                        <WnaButtonText
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            style={{ width: 256 }}
                            text={t(i18nKeys.actionLogOut)}
                            onPress={questionLogOut}
                            checkInternetConnection={false}
                            t={t}
                        />

                        {/* logout and delete */}
                        <WnaButtonText
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            style={{ width: 256 }}
                            text={t(i18nKeys.actionLogOutAndDeleteData)}
                            onPress={questionLogOutAndDeleteAll}
                            t={t}
                            checkInternetConnection={true}
                        />
                    </View>
                </View>
            </ScrollView>
        </WnaBaseScreenWithInfo>
    );
};
export default WnaProfileScreen;
