import {
    decryptLocal,
    encryptLocal,
} from "@infrastructure/services/WnaCryptoProvider";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Mutex } from "async-mutex";
import { Platform } from "react-native";
import WnaLogger from "wna-logger";
import { WnaIdbProvider } from "../WnaIdbProvider";

const mutex = new Mutex();

const getCleanKeyName = (key: string) => {
    if (key === null || key === "") return "KEY_IS_EMPTY";

    return key.replace(/[^A-Z0-9]+/gi, "_");
};

const getFromDbAsync = async (decrypt: boolean, cKey: string) => {
    let cItem = "";

    const encItem = ((await WnaIdbProvider.getItemAsync(cKey)) as string) ?? "";
    if (encItem === undefined || encItem === "") return "";

    if (decrypt)
        // @ts-ignore
        cItem = decryptLocal(encItem, cKey + cKey.length);
    else cItem = encItem;

    // @ts-ignore
    if (cItem.val !== undefined)
        // @ts-ignore
        cItem = cItem.val;

    return cItem;
};

const getFromLocalStorageAsync = async (decrypt: boolean, cKey: string) => {
    let cItem = "";
    const encItem = (await AsyncStorage.getItem(cKey)) ?? "";

    if (encItem === undefined || encItem === "") return "";

    if (decrypt)
        // @ts-ignore
        cItem = decryptLocal(encItem, cKey + cKey.length);
    else cItem = encItem;

    // @ts-ignore
    if (cItem.val !== undefined)
        // @ts-ignore
        cItem = cItem.val;

    return cItem;
};

// https://docs.expo.dev/versions/latest/sdk/securestore/
export default class WnaAsyncStorageProvider {
    public static async getItemAsync(
        key: string,
        decrypt: boolean,
        useDb: boolean
    ): Promise<string | null> {
        try {
            const cKey = getCleanKeyName(key);
            // WnaLogger.start(WnaAsyncStorageProvider.name, WnaAsyncStorageProvider.getItemAsync.name, cKey);
            let cItem: string;

            if (useDb) cItem = await getFromDbAsync(decrypt, cKey);
            else cItem = await getFromLocalStorageAsync(decrypt, cKey);

            return cItem;
        } catch (error) {
            WnaLogger.error(
                WnaAsyncStorageProvider.name,
                WnaAsyncStorageProvider.getItemAsync.name,
                error
            );
            WnaLogger.error(
                WnaAsyncStorageProvider.name,
                WnaAsyncStorageProvider.getItemAsync.name,
                key
            );
            return null;
        } finally {
            // WnaLogger.end(WnaAsyncStorageProvider.name, WnaAsyncStorageProvider.getItemAsync.name, cKey);
        }
    }

    public static async setItemAsync(
        key: string,
        item: string,
        encrypt: boolean,
        useDb: boolean
    ): Promise<void> {
        return await mutex.runExclusive(async () => {
            try {
                const cKey = getCleanKeyName(key);
                // WnaLogger.start(WnaAsyncStorageProvider.name, WnaAsyncStorageProvider.setItemAsync.name, cKey);
                const cItem = item;
                if (Platform.OS == "web" && encrypt && useDb) {
                    const encItem = encryptLocal(cItem, cKey + cKey.length);
                    await WnaIdbProvider.setItemAsync(cKey, encItem);
                } else if (Platform.OS == "web" && !encrypt && useDb) {
                    await WnaIdbProvider.setItemAsync(cKey, cItem);
                } else if (encrypt) {
                    const encItem = encryptLocal(cItem, cKey + cKey.length);
                    await AsyncStorage.setItem(cKey, encItem);
                } else {
                    // WnaLogger.info("Write to AsyncStorage: " + item);
                    await AsyncStorage.setItem(cKey, cItem);
                }
            } catch (error) {
                WnaLogger.error(
                    WnaAsyncStorageProvider.name,
                    WnaAsyncStorageProvider.setItemAsync.name,
                    error
                );
            } finally {
                // WnaLogger.end(WnaAsyncStorageProvider.name, WnaAsyncStorageProvider.setItemAsync.name, cKey);
            }
        });
    }

    public static async existsKeyAsync(key: string, useDb: boolean) {
        try {
            const cKey = getCleanKeyName(key);
            // WnaLogger.start(WnaAsyncStorageProvider.name, WnaAsyncStorageProvider.existsKeyAsync.name, cKey);
            if (useDb) {
                return await WnaIdbProvider.existsKeyAsync(cKey);
            } else {
                const keys = await AsyncStorage.getAllKeys();
                if (keys) return keys.findIndex((x) => x === key) > -1;

                return false;
            }
        } catch (error) {
            WnaLogger.error(
                WnaAsyncStorageProvider.name,
                WnaAsyncStorageProvider.existsKeyAsync.name,
                error
            );
            return false;
        } finally {
            // WnaLogger.end(WnaAsyncStorageProvider.name, WnaAsyncStorageProvider.existsKeyAsync.name, cKey);
        }
    }

    public static async clearAsync(prefix?: string | null) {
        try {
            WnaLogger.start(
                WnaAsyncStorageProvider.name,
                WnaAsyncStorageProvider.clearAsync.name,
                `prefix: '${prefix ?? ""}'`
            );
            if (prefix == null || prefix == "") await AsyncStorage.clear();

            await WnaIdbProvider.clearAsync(prefix);
        } catch (error) {
            WnaLogger.error(
                WnaAsyncStorageProvider.name,
                WnaAsyncStorageProvider.clearAsync.name,
                error
            );
        } finally {
            WnaLogger.end(
                WnaAsyncStorageProvider.name,
                WnaAsyncStorageProvider.clearAsync.name,
                `prefix: '${prefix ?? ""}'`
            );
        }
    }
}
