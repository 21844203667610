import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import WnaCatalogItem from "@domain/contracts/types/WnaCatalogItem";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import WnaRadioButton from "@ui/components/radio/WnaRadioButton";
import WnaRadioButtonVertical from "@ui/components/radio/WnaRadioButtonVertical";
import { Component } from "react";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";
import WnaSettingsItemFrame from "./WnaSettingsItemFrame";

export type WnaSettingsRadioButtonProps = {
    currentAppStyle: WnaAppStyle;
    currentAppTheme: WnaTheme;
    data: Array<WnaCatalogItem>;
    selectedId: Int32 | null;
    onSelect: (item: WnaCatalogItem) => void;
    isEditMode: boolean;
    text: string;
    icon: string;
    vertical?: boolean;
    horizontal?: boolean;
};

class WnaSettingsRadioButton extends Component<WnaSettingsRadioButtonProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaSettingsRadioButtonProps>
    ): boolean {
        return (
            nextProps.currentAppTheme.dark != this.props.currentAppTheme.dark ||
            nextProps.text != this.props.text ||
            nextProps.vertical != this.props.vertical ||
            nextProps.horizontal != this.props.horizontal ||
            nextProps.selectedId != this.props.selectedId ||
            nextProps.isEditMode != this.props.isEditMode
        );
    }

    render() {
        return (
            <WnaSettingsItemFrame
                currentAppStyle={this.props.currentAppStyle}
                currentAppTheme={this.props.currentAppTheme}
                text={this.props.text}
                icon={this.props.icon}>
                {this.props.vertical || !(this.props.horizontal ?? true) ? (
                    <WnaRadioButtonVertical
                        data={this.props.data}
                        selectedId={this.props.selectedId}
                        isEditMode={true}
                        onSelect={this.props.onSelect}
                    />
                ) : (
                    <WnaRadioButton
                        data={this.props.data}
                        selectedId={this.props.selectedId}
                        isEditMode={true}
                        onSelect={this.props.onSelect}
                    />
                )}
            </WnaSettingsItemFrame>
        );
    }
}

export default WnaSettingsRadioButton;
