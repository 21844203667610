import WnaCatalogItem from "@domain/contracts/types/WnaCatalogItem";
import { i18nKeys } from "@infrastructure/i18n/i18nKeys";
import { WnaStaticColors } from "@infrastructure/services/WnaThemeService";

export const WnaCatalogDefaultDiaryTab: Array<WnaCatalogItem> = [
    {
        id: 0,
        text: i18nKeys.catalogDefaultDiaryTabEntry,
        displayColor: "transparent",
        selectionColor: WnaStaticColors.staticCoolgray6,
    },
    {
        id: 1,
        text: i18nKeys.catalogDefaultDiaryTabMap,
        displayColor: "transparent",
        selectionColor: WnaStaticColors.staticCoolgray6,
    },
    {
        id: 2,
        text: i18nKeys.catalogDefaultDiaryTabPictures,
        displayColor: "transparent",
        selectionColor: WnaStaticColors.staticCoolgray6,
    },
];
