import WnaCatalogItem from "@domain/contracts/types/WnaCatalogItem";
import { i18nKeys } from "@infrastructure/i18n/i18nKeys";
import { WnaStaticColors } from "@infrastructure/services/WnaThemeService";

export const WnaCatalogYesNo: Array<WnaCatalogItem> = [
    {
        id: 0,
        text: i18nKeys.wordNo,
        displayColor: "transparent",
        selectionColor: WnaStaticColors.staticCoolgray6,
    },
    {
        id: 1,
        text: i18nKeys.wordYes,
        displayColor: "transparent",
        selectionColor: WnaStaticColors.staticCoolgray6,
    },
];
