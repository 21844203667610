import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import { WnaDiaryPreviewProps } from "@app/screens/diary/diaryEntry/preview/WnaDiaryPreviewScreenProps";
import { i18nKeys } from "@infrastructure/i18n/i18nKeys";
import WnaDiaryPrintProvider from "@infrastructure/services/WnaDiaryPrintProvider";
import getHtmlAsync from "@infrastructure/wnaApi/diaryGenerator/queries/GetHtml";
import WnaButtonHeader from "@ui/components/buttons/WnaButtonHeader";
import WnaButtonIcon from "@ui/components/buttons/WnaButtonIcon";
import WnaPaginationInfoView from "@ui/components/misc/WnaPaginationInfoView";
import WnaWebView from "@ui/components/misc/WnaWebView";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import WnaMultilineHeader from "@ui/components/tabbar/WnaMultilineHeader";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import WnaLogger from "wna-logger";
import { WnaPage } from "./WnaPage";

const WnaDiaryPreviewScreen: FC<WnaStackScreenProps> = (props) => {
    const {
        currentUser,
        appSettings,
        currentAppTheme,
        currentAppStyle,
        isLandscape,
    } = useContext(WnaAppContext);

    const pProps = props.route.params as WnaDiaryPreviewProps;
    if (currentUser == null || appSettings == null) return null;

    const [currentPage, setCurrentPage] = useState(new WnaPage(0, 0));
    const [isBusy, setIsBusy] = useState(true);
    const { t } = useTranslation(["common"]);
    const [html, setHtml] = useState("");
    const pagesToLoadLength = 1;
    const loadDataAsync = async (currentPageIndex: number) => {
        setIsBusy(true);
        try {
            const resp = await getHtmlAsync(
                currentUser,
                pProps.currentChapter?.identifier!,
                currentPageIndex,
                pagesToLoadLength
            );
            setHtml(resp.html);
            setCurrentPage(new WnaPage(resp.startIndex, resp.length));

            const title = "Druckvorschau";
            props.navigation.setOptions({
                title: title,
                headerTitle: () =>
                    WnaMultilineHeader(currentAppStyle, currentAppTheme, title),
                headerRight: () => (
                    <View style={{ alignItems: "center" }}>
                        <WnaButtonHeader
                            text={t(i18nKeys.actionPrint)}
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            iconName="print"
                            onPress={printToPdfAsync}
                            t={t}
                            checkInternetConnection={true}
                        />
                    </View>
                ),
            });
        } catch (e) {
            WnaLogger.error(WnaDiaryPreviewScreen.name, loadDataAsync.name, e);
        }
        setIsBusy(false);
    };

    const printToPdfAsync = async () => {
        setIsBusy(true);

        await WnaDiaryPrintProvider.printToPdfAsync(
            currentUser,
            pProps.currentChapter?.identifier!,
            t
        );
        setIsBusy(false);
        props.navigation.goBack();
    };

    const navigateToNextPage = async () => {
        if (currentPage.isNextVisible())
            await loadDataAsync(currentPage.nextIndex());
    };

    const navigateToPrevPage = async () => {
        if (currentPage.isPrevVisible())
            await loadDataAsync(currentPage.prevIndex());
    };

    useEffect(() => {
        props.navigation.setOptions({
            title: pProps.currentChapter?.designator!,
            headerTitle: () =>
                WnaMultilineHeader(
                    currentAppStyle,
                    currentAppTheme,
                    "",
                    pProps.currentChapter?.designator!,
                    t(i18nKeys.screenTitleDiaryPreview)
                ),
        });
        if (html === "") loadDataAsync(currentPage.index);
    }, [html]);

    return (
        <WnaBaseScreenWithInfo isBusy={isBusy}>
            <>
                <View
                    style={{
                        flex: 1,
                        padding: 16,
                    }}>
                    <WnaWebView
                        urlOrHtml={html}
                        onLoaded={() => {
                            setIsBusy(false);
                        }}
                    />
                </View>
                {isBusy ? null : (
                    <>
                        <WnaPaginationInfoView
                            index={currentPage.index}
                            length={currentPage.length}
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            isLandscape={isLandscape}
                        />

                        {/*prev*/}
                        {currentPage.isPrevVisible() ? (
                            <WnaButtonIcon
                                toolTip={t(i18nKeys.actionPreviousPage)}
                                currentAppStyle={currentAppStyle}
                                currentAppTheme={currentAppTheme}
                                iconName="arrow-left"
                                onPress={() => navigateToPrevPage()}
                                t={t}
                                checkInternetConnection={true}
                                style={{
                                    position: "absolute",
                                    bottom: 16,
                                    left: 16,
                                }}
                            />
                        ) : null}
                        {/*next*/}
                        {currentPage.isNextVisible() ? (
                            <WnaButtonIcon
                                toolTip={t(i18nKeys.actionNextPage)}
                                toolTipPosition={"left"}
                                currentAppStyle={currentAppStyle}
                                currentAppTheme={currentAppTheme}
                                iconName="arrow-right"
                                onPress={() => navigateToNextPage()}
                                t={t}
                                checkInternetConnection={true}
                                style={{
                                    position: "absolute",
                                    bottom: 16,
                                    right: 16,
                                }}
                            />
                        ) : null}
                    </>
                )}
            </>
        </WnaBaseScreenWithInfo>
    );
};

export default WnaDiaryPreviewScreen;
