import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import WnaLogger from "wna-logger";

const WnaAdminScreen: React.FC<WnaStackScreenProps> = () => {
    const { currentAppStyle } = useContext(WnaAppContext);

    const [isBusy] = useState(false);

    const loadDataAsync = async () => {
        WnaLogger.start(WnaAdminScreen.name, loadDataAsync.name);
        try {
        } catch (error) {
            WnaLogger.error(WnaAdminScreen.name, loadDataAsync.name, error);
        }
        WnaLogger.end(WnaAdminScreen.name, loadDataAsync.name);
    };

    useEffect(() => {
        loadDataAsync();
    }, []);

    return (
        <WnaBaseScreenWithInfo isBusy={isBusy}>
            <View style={currentAppStyle.containerCenterMaxWidth}></View>
        </WnaBaseScreenWithInfo>
    );
};

export default WnaAdminScreen;
