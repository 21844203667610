import WnaCatalogItem from "@domain/contracts/types/WnaCatalogItem";
import { i18nKeys } from "@infrastructure/i18n/i18nKeys";
import { WnaStaticColors } from "@infrastructure/services/WnaThemeService";

export const WnaCatalogMapType: Array<WnaCatalogItem> = [
    {
        id: 0,
        text: i18nKeys.catalogMapTypeSatellite,
        displayColor: "transparent",
        selectionColor: WnaStaticColors.staticCoolgray6,
    },
    {
        id: 1,
        text: i18nKeys.catalogMapTypeHybrid,
        displayColor: "transparent",
        selectionColor: WnaStaticColors.staticCoolgray6,
    },
    {
        id: 2,
        text: i18nKeys.catalogMapTypeRoadmap,
        displayColor: "transparent",
        selectionColor: WnaStaticColors.staticCoolgray6,
    },
    {
        id: 3,
        text: i18nKeys.catalogMapTypeTerrain,
        displayColor: "transparent",
        selectionColor: WnaStaticColors.staticCoolgray6,
    },
];
