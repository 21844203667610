import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import WnaDialogProvider from "@app/dialog/WnaDialogProvider";
import WnaToastProvider from "@app/toast/WnaToastProvider";
import WnaChapterDao from "@infrastructure/dao/WnaChapterDao";
import WnaDiaryEntryDao from "@infrastructure/dao/WnaDiaryEntryDao";
import WnaRouteDao from "@infrastructure/dao/WnaRouteDao";
import WnaUserDao from "@infrastructure/dao/WnaUserDao";
import { i18nKeys } from "@infrastructure/i18n/i18nKeys";
import WnaAsyncFileCacheProvider from "@infrastructure/services/storage/WnaAsyncFileCacheProvider";
import WnaAsyncStorageProvider from "@infrastructure/services/storage/WnaAsyncStorageProvider/WnaAsyncStorageProvider";
import WnaNavigationItem from "@ui/components/navigation/WnaNavigationItem";
import WnaNavigationList from "@ui/components/navigation/WnaNavigationList";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import { WnaMenuItem } from "@ui/types/WnaMenuItem";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { WnaApplicationConstants } from "wna-app-constants";
import WnaLogger from "wna-logger";

const WnaSettingsScreen: React.FC<WnaStackScreenProps> = (props) => {
    const {
        currentUser,
        currentUserSettings,
        currentAppTheme,
        currentAppStyle,
    } = useContext(WnaAppContext);
    const { t } = useTranslation(["common"]);
    const [isBusy, setIsBusy] = useState(false);
    const cancelActionRef = useRef(false);
    const [isBusyText, setIsBusyText] = useState("");
    const items: WnaMenuItem[] = [
        {
            screen: WnaApplicationConstants.ScreenNameSettingsDiary,
            text: t(i18nKeys.wordDiary),
            icon: "book-open",
            rightIcon: "angle-right",
            type: "screen",
            data: undefined,
        },
        {
            screen: WnaApplicationConstants.ScreenNameSettingsMap,
            text: t(i18nKeys.wordMap),
            icon: "map",
            rightIcon: "angle-right",
            type: "screen",
            data: undefined,
        },
        {
            screen: WnaApplicationConstants.ScreenNameSettingsAdvanced,
            text: t(i18nKeys.wordAdvanced),
            icon: "cogs",
            rightIcon: "angle-right",
            type: "screen",
            data: undefined,
        },
        {
            screen: "",
            text: t(i18nKeys.actionDownloadCache),
            icon: "download",
            rightIcon: "",
            type: "function",
            data: undefined,
        },
        {
            screen: "",
            text: t(i18nKeys.actionClearCache),
            icon: "trash-alt",
            rightIcon: "",
            type: "function",
            data: undefined,
        },
    ];

    const handleClick = async (text: string) => {
        try {
            WnaLogger.start(WnaSettingsScreen.name, handleClick.name, text);
            const currentItem = items.find((x) => x.text === text)!;

            if (currentItem.text == t(i18nKeys.actionClearCache)) {
                await clearCacheAsync();
                return;
            }

            if (currentItem.text == t(i18nKeys.actionDownloadCache)) {
                await downloadCacheAsync();
                return;
            }

            // navigate
            if (currentItem.screen == "") return;
            props.navigation.push(currentItem.screen, {
                currentItem,
            });
        } catch (error) {
            WnaLogger.error(WnaSettingsScreen.name, handleClick.name, error);
        } finally {
            WnaLogger.end(WnaSettingsScreen.name, handleClick.name, text);
        }
    };

    const renderItem = (currentItem: WnaMenuItem) => (
        <WnaNavigationItem
            currentAppStyle={currentAppStyle}
            currentAppTheme={currentAppTheme}
            text={currentItem.text}
            iconName={currentItem.icon}
            onPress={handleClick}
            iconRightName={currentItem.rightIcon}
            t={t}
        />
    );

    const downloadCacheAsync = async () => {
        let isComplete = false;
        try {
            WnaLogger.start(WnaSettingsScreen.name, downloadCacheAsync.name);

            if (currentUser == null) return;

            const goAhead = await WnaDialogProvider.showYesNoAsync(
                t(i18nKeys.questionLongRunningProcess)
            );
            if (!goAhead) return;

            WnaUserDao.disableCreateOrUpdate();
            setIsBusy(true);
            setIsBusyText("Loading Data ...");

            // chapters
            setIsBusyText("Downloading chapters ...");
            const chapters = await WnaChapterDao.readAllAsync(
                currentUser,
                currentUserSettings?.diaryOrderBy ?? 1,
                true
            );
            for (
                let chapterIndex = 0;
                chapterIndex < chapters.length;
                chapterIndex++
            ) {
                if (cancelActionRef.current) return;
                const chapter = chapters[chapterIndex];

                const pIsBusyItemText =
                    "Downloading " +
                    (chapterIndex + 1) +
                    " / " +
                    chapters.length;

                setIsBusyText(pIsBusyItemText);

                // chapter image
                await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                    chapter.imageUrl ?? "",
                    "png"
                );

                // diary entries
                const diaryEntries =
                    await WnaDiaryEntryDao.readAllByChapterAsync(
                        currentUser!,
                        chapter,
                        1,
                        true
                    );

                for (
                    let diaryEntryIndex = 0;
                    diaryEntryIndex < diaryEntries.length;
                    diaryEntryIndex++
                ) {
                    if (cancelActionRef.current) return;
                    const diaryEntry = diaryEntries[diaryEntryIndex];

                    const pIsBusyItemText =
                        "Downloading " +
                        (diaryEntryIndex + 1) +
                        " / " +
                        diaryEntries.length;

                    setIsBusyText(pIsBusyItemText);
                    await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                        diaryEntry.imageUrl ?? "",
                        "png"
                    );

                    // diary entry images
                    if (
                        diaryEntry.images != null &&
                        diaryEntry.images.length > 0
                    ) {
                        for (
                            let imageIndex = 0;
                            imageIndex < diaryEntry.images.length;
                            imageIndex++
                        ) {
                            if (cancelActionRef.current) return;

                            const image = diaryEntry.images[imageIndex];
                            const isBusyImageText =
                                pIsBusyItemText +
                                " ( " +
                                (imageIndex + 1) +
                                " / " +
                                diaryEntry.images.length +
                                " )";
                            setIsBusyText(isBusyImageText);

                            await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                                image.urlTh256 ?? "",
                                "png"
                            );
                            await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                                image.urlTh512 ?? "",
                                "png"
                            );
                            await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                                image.url ?? "",
                                "png"
                            );
                        }
                    }

                    if (cancelActionRef.current) return;

                    // route
                    if (diaryEntry.routeIdentifier != "")
                        await WnaRouteDao.readByIdentifierAsync(
                            currentUser,
                            diaryEntry.routeIdentifier
                        );

                    if (cancelActionRef.current) return;

                    const cacheKey =
                        "cinfo_" +
                        WnaDiaryEntryDao.getPath(
                            currentUser,
                            chapter,
                            diaryEntry.identifier
                        );
                    await WnaAsyncStorageProvider.setItemAsync(
                        cacheKey,
                        diaryEntry.images.length.toString(),
                        false,
                        false
                    );
                }
            }
        } catch (error) {
            WnaLogger.error(
                WnaSettingsScreen.name,
                downloadCacheAsync.name,
                error
            );
            WnaToastProvider.showError(t(i18nKeys.errorUnknown));
        } finally {
            WnaUserDao.enableCreateOrUpdate();
            setIsBusy(false);
            setIsBusyText("");
            WnaLogger.end(WnaSettingsScreen.name, downloadCacheAsync.name);

            if (isComplete)
                WnaToastProvider.showSuccess(t(i18nKeys.successDownload));
            else WnaToastProvider.showError(t(i18nKeys.errorActionCanceled));
        }
    };

    const clearCacheAsync = async () => {
        try {
            WnaLogger.start(WnaSettingsScreen.name, clearCacheAsync.name);

            if (currentUser == null) return;

            const goAhead = await WnaDialogProvider.showYesNoAsync(
                t(i18nKeys.questionClearCache)
            );
            if (!goAhead) return;

            await WnaAsyncFileCacheProvider.clearCacheAsync();
            await WnaAsyncStorageProvider.clearAsync("diaryentries");
            await WnaAsyncStorageProvider.clearAsync("chapters");
            await WnaAsyncStorageProvider.clearAsync("routes");

            WnaToastProvider.showSuccess(t(i18nKeys.success));
        } catch (error) {
            WnaLogger.error(
                WnaSettingsScreen.name,
                clearCacheAsync.name,
                error
            );
            WnaToastProvider.showError(t(i18nKeys.errorUnknown));
        } finally {
            WnaLogger.end(WnaSettingsScreen.name, clearCacheAsync.name);
        }
    };

    useEffect(() => {
        // back navigation
        props.navigation.addListener("beforeRemove", (e) => {
            cancelActionRef.current = true;
        });
    }, [props]);

    return (
        <WnaBaseScreenWithInfo
            isBusy={isBusy}
            isBusyText={isBusyText}
            onCancelAsync={() => (cancelActionRef.current = true)}>
            <WnaNavigationList items={items} renderItem={renderItem} />
        </WnaBaseScreenWithInfo>
    );
};

export default WnaSettingsScreen;
