import WnaAppSettings from "@domain/entities/WnaAppSettings";
import WnaUser from "@domain/entities/WnaUser";
import WnaAsyncStorageProvider from "@infrastructure/services/storage/WnaAsyncStorageProvider/WnaAsyncStorageProvider";
import { setJwt } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import isAliveAsync from "@infrastructure/wnaApi/healthcheck/IsAliveAsync";
import createOrUpdateUserAsync from "@infrastructure/wnaApi/user/commands/CreateOrUpdateUser";
import deleteUserAsync from "@infrastructure/wnaApi/user/commands/DeleteUser";
import verifyIdTokenAsync from "@infrastructure/wnaApi/user/commands/VerifyIdToken";
import verifyJwtAsync from "@infrastructure/wnaApi/user/commands/VerifyJwt";
import getAllUsersAsync from "@infrastructure/wnaApi/user/queries/GetAllUsers";
import { Mutex } from "async-mutex";
import WnaLogger from "wna-logger";

const _storage = "userCurrent";
const _encrypted = true;
const _useDb = false;
const _mutex = new Mutex();

export default class WnaUserDao {
    private static _isCreateOrUpdateDisabled: boolean;

    public static disableCreateOrUpdate() {
        WnaUserDao._isCreateOrUpdateDisabled = true;
    }

    public static enableCreateOrUpdate() {
        WnaUserDao._isCreateOrUpdateDisabled = false;
    }

    public static isAdmin(item: WnaUser, appSettings: WnaAppSettings | null) {
        //WnaLogger.info(WnaUserDao.name, WnaUserDao.isAdmin.name, "isAdmin: " + isAdmin);
        return (
            item.identifier !== "" &&
            appSettings != null &&
            appSettings.admins.findIndex(
                (identifier) => identifier === item.identifier
            ) > -1
        );
    }

    public static async createOrUpdateAsync(user: WnaUser) {
        return await _mutex.runExclusive(async () => {
            WnaLogger.start(
                WnaUserDao.name,
                WnaUserDao.createOrUpdateAsync.name
            );
            try {
                if (WnaUserDao._isCreateOrUpdateDisabled) {
                    WnaLogger.info("isCreateOrUpdateDisabled");
                    return this.getCurrentUserFromStorageAsync();
                }

                if (user.wnaApiJwt !== "") {
                    WnaLogger.info(
                        "wnaApiJwt is not empty - run createOrUpdateUserAsync"
                    );
                    const wnaApiJwt = user.wnaApiJwt;
                    user.wnaApiJwt = "";
                    user = await createOrUpdateUserAsync(user);
                    user.wnaApiJwt = wnaApiJwt;
                    await this.setCurrentUserToStorageAsync(user);
                } else {
                    WnaLogger.warn("wnaApiJwt not empty");
                }
                return user;
            } catch (error) {
                WnaLogger.error(
                    WnaUserDao.name,
                    WnaUserDao.createOrUpdateAsync.name,
                    error
                );
                return user;
            } finally {
                WnaLogger.end(
                    WnaUserDao.name,
                    WnaUserDao.createOrUpdateAsync.name
                );
            }
        });
    }

    public static async getCurrentUserFromStorageAsync() {
        const currentUserJsonInStorage =
            (await WnaAsyncStorageProvider.getItemAsync(
                _storage,
                _encrypted,
                _useDb
            )) ?? "";
        if (currentUserJsonInStorage === "") {
            setJwt("", "");
            return new WnaUser();
        }

        const user = new WnaUser(JSON.parse(currentUserJsonInStorage));
        setJwt(user.id, user.wnaApiJwt);
        return user;
    }

    public static async setCurrentUserToStorageAsync(wnaUser: WnaUser) {
        const currentUserJson = JSON.stringify(wnaUser);
        await WnaAsyncStorageProvider.setItemAsync(
            _storage,
            currentUserJson,
            _encrypted,
            _useDb
        );
    }

    public static async readAllAsync() {
        let ret = new Array<WnaUser>();
        try {
            WnaLogger.start(WnaUserDao.name, WnaUserDao.readAllAsync.name);
            ret = await getAllUsersAsync();
        } catch (error) {
            WnaLogger.error(
                WnaUserDao.name,
                WnaUserDao.readAllAsync.name,
                error
            );
        } finally {
            WnaLogger.end(WnaUserDao.name, WnaUserDao.readAllAsync.name);
        }
        return ret;
    }

    public static async deleteAsync() {
        try {
            WnaLogger.start(WnaUserDao.name, WnaUserDao.deleteAsync.name);
            const currentUser = await this.getCurrentUserFromStorageAsync();
            if (currentUser.id === "") return;

            await deleteUserAsync(currentUser);
            await WnaAsyncStorageProvider.clearAsync();
        } catch (error) {
            WnaLogger.error(
                WnaUserDao.name,
                WnaUserDao.deleteAsync.name,
                error
            );
        } finally {
            WnaLogger.end(WnaUserDao.name, WnaUserDao.deleteAsync.name);
        }
    }

    public static async verifyIdTokenAsync(userId: string, idToken: string) {
        try {
            WnaLogger.start(
                WnaUserDao.name,
                WnaUserDao.verifyIdTokenAsync.name
            );
            return await verifyIdTokenAsync(userId, idToken);
        } catch (error) {
            WnaLogger.error(
                WnaUserDao.name,
                WnaUserDao.verifyIdTokenAsync.name,
                error
            );
            return "";
        } finally {
            WnaLogger.end(WnaUserDao.name, WnaUserDao.verifyIdTokenAsync.name);
        }
    }

    public static async verifyJwtAsync(userId: string, jwt: string) {
        try {
            WnaLogger.start(WnaUserDao.name, WnaUserDao.verifyJwtAsync.name);
            return await verifyJwtAsync(userId, jwt);
        } catch (error) {
            WnaLogger.error(
                WnaUserDao.name,
                WnaUserDao.verifyJwtAsync.name,
                error
            );
            return "";
        } finally {
            WnaLogger.end(WnaUserDao.name, WnaUserDao.verifyJwtAsync.name);
        }
    }

    public static async isAlive() {
        try {
            WnaLogger.start(WnaUserDao.name, WnaUserDao.isAlive.name);
            return await isAliveAsync();
        } catch (error) {
            WnaLogger.error(WnaUserDao.name, WnaUserDao.isAlive.name, error);
            return false;
        } finally {
            WnaLogger.end(WnaUserDao.name, WnaUserDao.isAlive.name);
        }
    }
}
