import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import WnaAppointmentListHeader from "@app/screens/appointment/list/WnaAppointmentListHeader";
import WnaAppointment from "@domain/entities/WnaAppointment";
import WnaAppointmentDao from "@infrastructure/dao/WnaAppointmentDao";
import WnaUserDao from "@infrastructure/dao/WnaUserDao";
import { i18nKeys } from "@infrastructure/i18n/i18nKeys";
import { dateToStringDateWeekdayShort } from "@infrastructure/services/WnaDateTimeService";
import { useIsFocused } from "@react-navigation/native";
import WnaButtonIcon from "@ui/components/buttons/WnaButtonIcon";
import WnaNavigationItem from "@ui/components/navigation/WnaNavigationItem";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import WnaNotificationHeaderRight from "@ui/components/tabbar/WnaNotificationHeaderRight";
import WnaRefreshParam, {
    WnaRefreshAction,
} from "@ui/events/refresh/WnaRefreshParam";
import { useOnRefreshListener } from "@ui/events/refresh/WnaUseOnRefreshListener";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { FlatList, RefreshControl, View } from "react-native";
import { WnaApplicationConstants } from "wna-app-constants";
import WnaLogger from "wna-logger";
// https://github.com/wix/react-native-calendars
const WnaAppointmentListScreen: React.FC<WnaStackScreenProps> = (props) => {
    const {
        isInternetReachable,
        appSettings,
        currentAppTheme,
        currentAppStyle,
        currentUser,
        unreadNotificationCount,
    } = useContext(WnaAppContext);
    const [extraData, setExtraData] = useState(new Date());
    const [items, setItems] = useState(Array<WnaAppointment>);
    const itemsRef = useRef(items);
    const isFocused = useIsFocused();
    const [isBusy, setIsBusy] = useState(true);
    const { t } = useTranslation(["common"]);

    const loadItemsFromDbAsync = async (forceReload: boolean) => {
        let newItems = new Array<WnaAppointment>();
        try {
            let needReload = false;

            if (itemsRef.current.length < 1) needReload = true;

            if (forceReload) needReload = true;

            if (needReload) {
                setIsBusy(true);
                if (isInternetReachable === true) {
                    WnaLogger.info(
                        "internetIsReachable --> will load from FireStore"
                    );
                    newItems =
                        await WnaAppointmentDao.readAllAsync(forceReload);
                } else {
                    WnaLogger.info(
                        "internetIsReachable --> will load from Cache"
                    );
                    newItems =
                        await WnaAppointmentDao.readAllOfflineAsync(
                            forceReload
                        );
                    if (newItems.length < 1)
                        newItems = await WnaAppointmentDao.readAllAsync(true);
                }
                setItems(newItems);
                itemsRef.current = newItems;
                setIsBusy(false);
            }
        } catch (error) {
            WnaLogger.error(
                WnaAppointmentListScreen.name,
                loadItemsFromDbAsync.name,
                error
            );
        }

        setIsBusy(false);
        WnaLogger.end(WnaAppointmentListScreen.name, loadItemsFromDbAsync.name);
    };

    useEffect(() => {
        WnaLogger.start(WnaAppointmentListScreen.name, useEffect.name);

        props.navigation.setOptions({
            headerRight: () => (
                <WnaNotificationHeaderRight
                    unreadNotificationCount={unreadNotificationCount}
                    currentAppStyle={currentAppStyle}
                    currentAppTheme={currentAppTheme}
                    currentUser={currentUser}
                    t={t}
                />
            ),
        });

        if (isFocused)
            loadItemsFromDbAsync(false).then(() => {
                WnaLogger.info(`${loadItemsFromDbAsync.name} executed`);
            });

        WnaLogger.end(WnaAppointmentListScreen.name, useEffect.name);
    }, [isFocused, currentAppTheme, currentUser, unreadNotificationCount]);

    useOnRefreshListener(async (p) => {
        await onRefreshAsync(p.refreshParameter);
    }, []);

    const onRefreshAsync = async (p: WnaRefreshParam) => {
        if (p.sender != WnaApplicationConstants.ScreenNameAppointmentDetails)
            return;

        WnaLogger.start(WnaAppointmentListScreen.name, onRefreshAsync.name);
        switch (p.action) {
            case WnaRefreshAction.create:
                await loadItemsFromDbAsync(true);
                break;
            case WnaRefreshAction.update:
                itemsRef.current[
                    itemsRef.current.findIndex(
                        (x) => x.identifier == p.context.identifier
                    )
                ] = p.context;
                setExtraData(new Date());
                break;
            case WnaRefreshAction.delete:
                await loadItemsFromDbAsync(true);
                break;
        }
        WnaLogger.end(WnaAppointmentListScreen.name, onRefreshAsync.name);
        return p;
    };

    const navigateToAccommodationScreen = () => {
        props.navigation.push(
            WnaApplicationConstants.ScreenNameAccommodation,
            {}
        );
    };

    const renderItem = (currentItem: WnaAppointment) => {
        const currentItemIndex = items.findIndex(
            (x) => x.identifier === currentItem.identifier
        );
        const previousItem =
            currentItemIndex > 0 ? items[currentItemIndex - 1] : undefined;
        const dtStartString = dateToStringDateWeekdayShort(
            currentItem.dateStart
        );
        const dtEndString = dateToStringDateWeekdayShort(currentItem.dateEnd);
        let displayText = dtStartString;
        if (dtEndString != dtStartString) displayText += " - " + dtEndString;

        return (
            <WnaNavigationItem
                currentAppStyle={currentAppStyle}
                currentAppTheme={currentAppTheme}
                text={displayText}
                iconName={"walking"}
                onPress={() =>
                    props.navigation.push(
                        WnaApplicationConstants.ScreenNameAppointmentDetails,
                        {
                            currentItem: currentItem,
                            previousItem: previousItem,
                        }
                    )
                }
                t={t}
            />
        );
    };

    return (
        <>
            <Helmet>
                <meta name="description" content="Anstehende Kurse" />
                <link
                    rel="canonical"
                    href={WnaApplicationConstants.AppBaseUrl + "/Kurse"}
                />
            </Helmet>

            <WnaBaseScreenWithInfo isBusy={isBusy}>
                <FlatList
                    contentContainerStyle={{
                        paddingBottom: 80,
                        paddingTop: 16,
                        paddingHorizontal: 16,
                    }}
                    ListHeaderComponentStyle={{
                        paddingBottom: 8,
                        paddingHorizontal: 0,
                    }}
                    ItemSeparatorComponent={() => (
                        <View style={{ height: 8 }}></View>
                    )}
                    data={items}
                    extraData={extraData}
                    keyExtractor={(item) => item.identifier}
                    refreshControl={
                        <RefreshControl
                            refreshing={isBusy}
                            onRefresh={() => loadItemsFromDbAsync(true)}
                        />
                    }
                    ListHeaderComponent={
                        <WnaAppointmentListHeader
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            navigateToAccommodationScreen={
                                navigateToAccommodationScreen
                            }
                            t={t}
                        />
                    }
                    renderItem={({ item: currentItem }) =>
                        renderItem(currentItem)
                    }
                />

                {currentUser != null &&
                currentUser.id != "" &&
                WnaUserDao.isAdmin(currentUser, appSettings) ? (
                    <WnaButtonIcon
                        toolTip={t(i18nKeys.actionCreateAppointment)}
                        toolTipPosition="left"
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        iconName="plus"
                        style={{ position: "absolute", bottom: 16, right: 16 }}
                        onPress={() => {
                            const previousItem =
                                items.length > 0 ? items[0] : undefined;
                            props.navigation.push(
                                WnaApplicationConstants.ScreenNameAppointmentDetails,
                                {
                                    currentItem: null,
                                    previousItem: previousItem,
                                }
                            );
                        }}
                        t={t}
                        checkInternetConnection={false}
                    />
                ) : null}
            </WnaBaseScreenWithInfo>
        </>
    );
};
export default WnaAppointmentListScreen;
