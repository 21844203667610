import WnaDialogProvider from "@app/dialog/WnaDialogProvider";
import WnaToastProvider from "@app/toast/WnaToastProvider";
import WnaUser from "@domain/entities/WnaUser";
import { i18nKeys } from "@infrastructure/i18n/i18nKeys";
import { blobToBase64Async } from "@infrastructure/services/WnaBase64Service";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import { TFunction } from "i18next";
import { Platform } from "react-native";
import WnaLogger from "wna-logger";
import { getPdfAsync } from "../wnaApi/diaryGenerator/queries/GetPdf";

const showPdfAsync = async (data: Blob) => {
    if (Platform.OS !== "web") {
        const b64 = await blobToBase64Async(data);
        const fileUri = `${FileSystem.documentDirectory}/pdf.pdf`;
        await FileSystem.writeAsStringAsync(fileUri, b64, {
            encoding: "base64",
        });
        await Sharing.shareAsync(fileUri);
    } else {
        const fileURL = URL.createObjectURL(data);
        window.open(fileURL, "_blank");
    }
};

export default class WnaDiaryPrintProvider {
    public static async printToPdfAsync(
        user: WnaUser,
        chapterIdentifier: string,
        t: TFunction<string[], undefined>
    ) {
        try {
            WnaLogger.start(
                WnaDiaryPrintProvider.name,
                WnaDiaryPrintProvider.printToPdfAsync.name
            );

            const getPdfResponse = await getPdfAsync(user, chapterIdentifier);
            switch (getPdfResponse.httpStatusCode) {
                case 200:
                    await showPdfAsync(getPdfResponse.data);
                    break;
                case 201:
                case 202:
                case 203:
                case 204:
                case 205:
                case 206:
                    // WnaToastProvider.showSuccess(
                    //     t(i18nKeys.infoWorkInProgress)
                    // );
                    WnaDialogProvider.showOk(t(i18nKeys.infoPdfCreation));
                    break;
                default:
                    WnaToastProvider.showError(t(i18nKeys.errorUnknown));
                    break;
            }
        } catch (error) {
            WnaLogger.error(
                WnaDiaryPrintProvider.name,
                WnaDiaryPrintProvider.printToPdfAsync.name,
                error
            );
        } finally {
            WnaLogger.end(
                WnaDiaryPrintProvider.name,
                WnaDiaryPrintProvider.printToPdfAsync.name
            );
        }
    }
}
