import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import { WnaCatalogDefaultDiaryTab } from "@domain/constants/catalogs/WnaCatalogDefaultDiaryTab";
import { WnaCatalogOrderBy } from "@domain/constants/catalogs/WnaCatalogOrderBy";
import { WnaCatalogYesNo } from "@domain/constants/catalogs/WnaCatalogYesNo";
import WnaCatalogItem from "@domain/contracts/types/WnaCatalogItem";
import WnaUserSettings from "@domain/entities/WnaUserSettings";
import WnaUserSettingsDao from "@infrastructure/dao/WnaUserSettingsDao";
import { i18nKeys } from "@infrastructure/i18n/i18nKeys";
import WnaNavigationList from "@ui/components/navigation/WnaNavigationList";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import WnaSettingsRadioButton from "@ui/components/settings/WnaSettingsRadioButton";
import { WnaMenuItem } from "@ui/types/WnaMenuItem";
import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import WnaLogger from "wna-logger";

const WnaSettingsDiaryScreen: React.FC<WnaStackScreenProps> = () => {
    const {
        currentAppStyle,
        currentAppTheme,
        currentUserSettings,
        setCurrentUserSettings,
    } = useContext(WnaAppContext);
    const currentUserSettingsRef = useRef(currentUserSettings);
    const { t } = useTranslation(["common"]);
    const items: WnaMenuItem[] = [];

    items.push(
        {
            screen: "",
            text: t(i18nKeys.settingsDiaryOrderItems),
            icon: "book-open",
            rightIcon: "",
            type: "diaryOrderBy",
            data: undefined,
        },
        {
            screen: "",
            text: t(i18nKeys.settingsDiaryDefaultTab),
            icon: "book-open",
            rightIcon: "",
            type: "diaryDefaultTab",
            data: undefined,
        },
        {
            screen: "",
            text: t(i18nKeys.settingsDiarySwitchToDefaultTab),
            icon: "book-open",
            rightIcon: "",
            type: "diaryRestoreDiaryTab",
            data: undefined,
        }
    );

    const onSelectDiaryDefaultTab = async (currentItem: WnaCatalogItem) => {
        WnaLogger.start(
            WnaSettingsDiaryScreen.name,
            onSelectDiaryDefaultTab.name
        );
        const newUserSettings = new WnaUserSettings(
            currentUserSettingsRef.current ?? new WnaUserSettings()
        );
        newUserSettings.diaryDefaultTab = currentItem.id;
        setCurrentUserSettings(newUserSettings);
        currentUserSettingsRef.current = newUserSettings;
        await WnaUserSettingsDao.createOrUpdateAsync(newUserSettings);
        WnaLogger.end(
            WnaSettingsDiaryScreen.name,
            onSelectDiaryDefaultTab.name
        );
    };

    const onSelectDiaryOrderBy = async (currentItem: WnaCatalogItem) => {
        WnaLogger.start(WnaSettingsDiaryScreen.name, onSelectDiaryOrderBy.name);
        const newUserSettings = new WnaUserSettings(
            currentUserSettingsRef.current ?? new WnaUserSettings()
        );
        newUserSettings.diaryOrderBy = currentItem.id < 1 ? 0 : 1;
        setCurrentUserSettings(newUserSettings);
        currentUserSettingsRef.current = newUserSettings;
        await WnaUserSettingsDao.createOrUpdateAsync(newUserSettings);
        WnaLogger.end(WnaSettingsDiaryScreen.name, onSelectDiaryOrderBy.name);
    };

    const onSelectDiaryRestoreDiaryTab = async (
        currentItem: WnaCatalogItem
    ) => {
        WnaLogger.start(
            WnaSettingsDiaryScreen.name,
            onSelectDiaryRestoreDiaryTab.name
        );
        const newUserSettings = new WnaUserSettings(
            currentUserSettingsRef.current ?? new WnaUserSettings()
        );
        newUserSettings.diaryRestoreDiaryTab = currentItem.id === 1;
        setCurrentUserSettings(newUserSettings);
        currentUserSettingsRef.current = newUserSettings;
        await WnaUserSettingsDao.createOrUpdateAsync(newUserSettings);
        WnaLogger.end(
            WnaSettingsDiaryScreen.name,
            onSelectDiaryRestoreDiaryTab.name
        );
    };

    const renderItem = (currentItem: any) => {
        switch (currentItem.type) {
            case "diaryDefaultTab":
                return (
                    <WnaSettingsRadioButton
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogDefaultDiaryTab}
                        selectedId={currentUserSettings?.diaryDefaultTab ?? 0}
                        isEditMode
                        onSelect={onSelectDiaryDefaultTab}
                    />
                );
            case "diaryOrderBy":
                return (
                    <WnaSettingsRadioButton
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogOrderBy}
                        selectedId={currentUserSettings?.diaryOrderBy ?? 1}
                        isEditMode
                        onSelect={onSelectDiaryOrderBy}
                    />
                );
            case "diaryRestoreDiaryTab":
                return (
                    <WnaSettingsRadioButton
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogYesNo}
                        selectedId={
                            currentUserSettings?.diaryRestoreDiaryTab == true
                                ? 1
                                : 0
                        }
                        isEditMode
                        onSelect={onSelectDiaryRestoreDiaryTab}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <WnaBaseScreenWithInfo>
            <WnaNavigationList items={items} renderItem={renderItem} />
        </WnaBaseScreenWithInfo>
    );
};

export default WnaSettingsDiaryScreen;
