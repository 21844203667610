import { WnaAppContext } from "@app/WnaAppContext";
import { WnaImageViewerModalProps } from "@app/modals/WnaImageViewerModal/WnaImageViewerModalProps";
import WnaFileInfo from "@domain/contracts/types/WnaFileInfo";
import { i18nKeys } from "@infrastructure/i18n/i18nKeys";
import { ReactNativeZoomableView } from "@openspacelabs/react-native-zoomable-view";
import { FlashList } from "@shopify/flash-list";
import WnaButtonIconInnerIcon from "@ui/components/buttons/WnaButtonIconInnerIcon";
import WnaPressable from "@ui/components/buttons/WnaPressable";
import WnaImage from "@ui/components/images/WnaImage";
import WnaActivityIndicator from "@ui/components/misc/WnaActivityIndicator";
import WnaPaginationInfoView from "@ui/components/misc/WnaPaginationInfoView";
import * as React from "react";
import { createRef, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";
import Carousel from "react-native-reanimated-carousel";
import WnaLogger from "wna-logger";

const _navBtnWidth = 64;
const _navBtnHeight = 64;
const _navBtnBgColor = "transparent"; //WnaColorHelper.convertHexToRgba("#000000", 0.5);
const _portraitBottom = 48;

const WnaImageViewerModal: React.FC<WnaImageViewerModalProps> = (props) => {
    const {
        currentWindowWidth,
        currentWindowHeight,
        isLandscape,
        currentAppStyle,
        currentAppTheme,
    } = useContext(WnaAppContext);
    const currentWindowWidthRef = useRef(currentWindowWidth);
    const currentWindowHeightRef = useRef(currentWindowHeight);
    const isLandscapeRef = useRef(isLandscape);

    const [isBusy, setIsBusy] = useState(true);
    const isBusyRef = useRef(isBusy);
    const onCancel = props.modal.closeModal as () => void;
    const onFirst = props.modal.getParam("onFirst", () => {}) as () => void;
    const onLast = props.modal.getParam("onLast", () => {}) as () => void;
    const onIndexChanged = props.modal.getParam(
        "onIndexChanged",
        (currentIndex: number) => {}
    ) as (currentIndex: number) => {};

    const [images, setImages] = useState<Array<WnaFileInfo>>([]);
    const imagesRef = useRef(images);

    const [currentIndex, setCurrentIndex] = useState(0);
    const currentIndexRef = useRef(currentIndex);

    const cRef = useRef(null);
    const flRef = useRef<FlashList<WnaFileInfo>>(null);

    const [isScrollEnabled, setIsScrollEnabled] = useState(true);
    const isScrollEnabledRef = useRef(isScrollEnabled);
    const [currentZoomLevels, setCurrentZoomLevels] = useState<Array<number>>(
        []
    );
    const currentZoomLevelsRef = useRef(currentZoomLevels);
    const [isZoomEnabled, setIsZoomEnabled] = useState(true);
    const isZoomEnabledRef = useRef(isZoomEnabled);
    const [zoomableRefs, setZoomableRefs] = React.useState<
        Array<React.RefObject<ReactNativeZoomableView>>
    >([]);

    const [isNextBtnVisible, setIsNextBtnVisible] = useState(false);
    const [isLastBtnVisible, setIsLastBtnVisible] = useState(false);
    const [isPrevBtnVisible, setIsPrevBtnVisible] = useState(false);
    const [isFirstBtnVisible, setIsFirstBtnVisible] = useState(false);

    const { t } = useTranslation(["common"]);

    const setZoomLevel = (index: number, zoomLevel: number) => {
        WnaLogger.start(
            WnaImageViewerModal.name,
            setZoomLevel.name,
            "index: " + index + " | zoomLevel: " + zoomLevel
        );
        let newZoomLevels = [...currentZoomLevels];
        newZoomLevels[index] = zoomLevel;
        setCurrentZoomLevels(newZoomLevels);
        currentZoomLevelsRef.current = newZoomLevels;
        setBtnStates(index);
        WnaLogger.end(
            WnaImageViewerModal.name,
            setZoomLevel.name,
            "index: " + index + " | zoomLevel: " + zoomLevel
        );
    };

    const setBtnStates = (index: number) => {
        WnaLogger.start(
            WnaImageViewerModal.name,
            setBtnStates.name,
            "index: " + index
        );
        WnaLogger.info(
            WnaImageViewerModal.name,
            setBtnStates.name,
            "currentZoomLevelsRef: " + currentZoomLevelsRef.current[index]
        );
        if (currentZoomLevelsRef.current[index] > 1) {
            WnaLogger.info(
                WnaImageViewerModal.name,
                setBtnStates.name,
                "image is zoomed"
            );
            setIsZoomEnabled(true);
            isZoomEnabledRef.current = true;
            setIsScrollEnabled(false);
            isScrollEnabledRef.current = false;
            setIsFirstBtnVisible(false);
            setIsLastBtnVisible(false);
            setIsNextBtnVisible(false);
            setIsPrevBtnVisible(false);
            return;
        }

        WnaLogger.info(
            WnaImageViewerModal.name,
            setBtnStates.name,
            "image is NOT zoomed"
        );
        setIsZoomEnabled(true);
        isZoomEnabledRef.current = true;
        setIsScrollEnabled(true);
        isScrollEnabledRef.current = true;

        if (index > 0) {
            // between - maybe not last
            setIsFirstBtnVisible(false);
            setIsLastBtnVisible(false);
            setIsPrevBtnVisible(true); //setIsPrevBtnVisible(Platform.OS === "web");
        } else {
            // first btn
            setIsLastBtnVisible(false);
            setIsPrevBtnVisible(false);
            setIsFirstBtnVisible(true);
        }

        if (index < imagesRef.current.length - 1) {
            // between - but not last
            //setIsNextBtnVisible(Platform.OS === "web");
            setIsNextBtnVisible(true);
            setIsLastBtnVisible(false);
        } else {
            // last btn
            setIsNextBtnVisible(false);
            setIsLastBtnVisible(true);
        }

        WnaLogger.end(
            WnaImageViewerModal.name,
            setBtnStates.name,
            "index: " + index
        );
    };

    const renderItem = (index: number, item: WnaFileInfo) => {
        index = currentIndexRef.current;
        item = images[index];

        // console.log(`${index} - ${item.fileName}`);

        return (
            <View
                style={[
                    styles.imgContainer,
                    {
                        width: currentWindowWidthRef.current,
                        height: currentWindowHeightRef.current,
                    },
                    Platform.OS == "web"
                        ? {
                              // @ts-ignore
                              cursor: "zoom",
                          }
                        : null,
                ]}
                key={"View" + index}>
                <ReactNativeZoomableView
                    // Give these to the zoomable view, so it can apply the boundaries around the actual content.
                    // Need to make sure the content is actually centered and the width and height are
                    // dimensions when it's rendered naturally. Not the intrinsic size.
                    // For example, an image with an intrinsic size of 400x200 will be rendered as 300x150 in this case.
                    // Therefore, we'll feed the zoomable view the 300x150 size.
                    key={"ZoomableView" + index}
                    ref={zoomableRefs[index]}
                    contentWidth={currentWindowWidthRef.current}
                    contentHeight={currentWindowHeightRef.current}
                    maxZoom={4}
                    minZoom={1}
                    zoomStep={1}
                    onZoomBefore={(
                        event,
                        gestureState,
                        zoomableViewEventObject
                    ) => {
                        WnaLogger.start(
                            WnaImageViewerModal.name,
                            "onZoomBefore",
                            "zoomLevel: " + zoomableViewEventObject.zoomLevel
                        );
                        //setZoomLevel(index, zoomableViewEventObject.zoomLevel);
                        setBtnStates(index);
                        isScrollEnabledRef.current = false;
                        WnaLogger.end(
                            WnaImageViewerModal.name,
                            "onZoomBefore",
                            "zoomLevel: " + zoomableViewEventObject.zoomLevel
                        );
                    }}
                    onZoomAfter={(
                        event,
                        gestureState,
                        zoomableViewEventObject
                    ) => {
                        WnaLogger.start(
                            WnaImageViewerModal.name,
                            "onZoomAfter",
                            "zoomLevel: " + zoomableViewEventObject.zoomLevel
                        );
                        setBtnStates(index);
                        //setZoomLevel(index, zoomableViewEventObject.zoomLevel);
                        WnaLogger.end(
                            WnaImageViewerModal.name,
                            "onZoomAfter",
                            "zoomLevel: " + zoomableViewEventObject.zoomLevel
                        );
                    }}
                    onZoomEnd={(
                        event,
                        gestureState,
                        zoomableViewEventObject
                    ) => {
                        WnaLogger.start(
                            WnaImageViewerModal.name,
                            "onZoomEnd",
                            "zoomLevel: " + zoomableViewEventObject.zoomLevel
                        );
                        setZoomLevel(index, zoomableViewEventObject.zoomLevel);
                        WnaLogger.end(
                            WnaImageViewerModal.name,
                            "onZoomEnd",
                            "zoomLevel: " + zoomableViewEventObject.zoomLevel
                        );
                    }}
                    onDoubleTapAfter={(event, zoomableViewEventObject) => {
                        // if (Platform.OS !== "web")
                        //     return;

                        WnaLogger.start(
                            WnaImageViewerModal.name,
                            "onDoubleTapAfter",
                            "zoomLevel: " + zoomableViewEventObject.zoomLevel
                        );
                        setZoomLevel(index, zoomableViewEventObject.zoomLevel);
                        WnaLogger.end(
                            WnaImageViewerModal.name,
                            "onDoubleTapAfter",
                            "zoomLevel: " + zoomableViewEventObject.zoomLevel
                        );
                    }}
                    zoomEnabled={isZoomEnabledRef.current}
                    disablePanOnInitialZoom={true}>
                    <WnaImage
                        currentAppTheme={currentAppTheme}
                        style={{
                            width: currentWindowWidthRef.current,
                            height: currentWindowHeightRef.current,
                            resizeMode: "contain",
                        }}
                        imageUrl={item.url}
                        thumbnailUrl={item.fileName512}
                    />
                </ReactNativeZoomableView>
            </View>
        );
    };

    const loadDataAsync = async () => {
        try {
            WnaLogger.start(
                WnaImageViewerModal.name,
                useEffect.name,
                "loading images into Source"
            );
            const propsImages = props.modal.getParam(
                "images",
                new Array<WnaFileInfo>()
            ) as Array<WnaFileInfo>;
            if (propsImages.length > 0) {
                const currentImage = props.modal.getParam("currentImage", {
                    url: "",
                } as WnaFileInfo);
                let initialIndex = 0;
                const zoomableRefs = new Array<
                    React.RefObject<ReactNativeZoomableView>
                >();
                const zoomLevels = new Array<number>();
                for (let i = 0; i < propsImages.length; i++) {
                    if (propsImages[i].url == "") continue;

                    const ref = createRef<ReactNativeZoomableView>();
                    zoomableRefs.push(ref);
                    zoomLevels.push(1);
                    if (propsImages[i].url === currentImage.url)
                        initialIndex = i;
                }
                WnaLogger.info("initial index will be: " + initialIndex);
                setCurrentIndex(initialIndex);
                currentIndexRef.current = initialIndex;
                setZoomableRefs(zoomableRefs);
                setCurrentZoomLevels(zoomLevels);
                currentZoomLevelsRef.current = zoomLevels;
                setImages(propsImages);
                imagesRef.current = propsImages;

                // WnaLogger.info(JSON.stringify(currentImage));
                WnaLogger.end(
                    WnaImageViewerModal.name,
                    useEffect.name,
                    "loading images into Source"
                );
            }
            currentWindowWidthRef.current = currentWindowWidth;
            currentWindowHeightRef.current = currentWindowHeight;
            isLandscapeRef.current = isLandscape;
            WnaLogger.info(
                WnaImageViewerModal.name,
                useEffect.name,
                "setting buttons"
            );
            setBtnStates(currentIndexRef.current);
        } catch (error) {
            WnaLogger.error(
                WnaImageViewerModal.name,
                loadDataAsync.name,
                error
            );
        } finally {
            setIsBusy(false);
            isBusyRef.current = false;
        }
    };

    useEffect(() => {
        WnaLogger.start(WnaImageViewerModal.name, useEffect.name);
        currentWindowHeightRef.current = currentWindowHeight;
        currentWindowWidthRef.current = currentWindowWidth;
        isLandscapeRef.current = isLandscape;

        if (isBusyRef.current) loadDataAsync();

        WnaLogger.end(WnaImageViewerModal.name, useEffect.name);
    }, [props, currentWindowWidth, currentWindowHeight, isLandscape]);

    return (
        <View
            style={[
                {
                    width: currentWindowWidthRef.current,
                    height: currentWindowHeightRef.current,
                },
            ]}>
            {isBusy ? (
                <View style={currentAppStyle.containerCenterCenter}>
                    <WnaActivityIndicator currentAppTheme={currentAppTheme} />
                </View>
            ) : (
                <>
                    <Carousel
                        ref={cRef}
                        // loop
                        width={currentWindowWidthRef.current}
                        height={currentWindowHeightRef.current}
                        data={images}
                        defaultIndex={currentIndex}
                        scrollAnimationDuration={1000}
                        snapEnabled={true}
                        autoPlayInterval={2000}
                        // pagingEnabled
                        //enabled={isScrollEnabled && Platform.OS != "web"}
                        enabled={false}
                        panGestureHandlerProps={{
                            enableTrackpadTwoFingerGesture: false,
                        }}
                        onSnapToItem={(index) => {
                            WnaLogger.info(
                                WnaImageViewerModal.name,
                                "onSnapToItem",
                                "current index: " + index
                            );
                            setCurrentIndex(index);
                            currentIndexRef.current = index;
                            onIndexChanged(index);
                            setBtnStates(index);
                            // renderItem(images[index]);
                        }}
                        renderItem={({ index, item }) =>
                            renderItem(index, item)
                        }
                        vertical={false}
                    />

                    {/* pic / pics */}
                    {isScrollEnabled ? (
                        <WnaPaginationInfoView
                            index={currentIndex}
                            length={images.length}
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            isLandscape={isLandscape}
                        />
                    ) : null}

                    {/* prev button */}
                    {isPrevBtnVisible ? (
                        <WnaPressable
                            toolTip=""
                            style={
                                isLandscape
                                    ? styles.btnPrevLandscape
                                    : styles.btnPrevPortrait
                            }
                            onPress={() => {
                                if (currentIndexRef.current < 1) return;

                                const newIndex = currentIndexRef.current - 1;
                                if (Platform.OS == "web") {
                                    setCurrentIndex(newIndex);
                                    currentIndexRef.current = newIndex;
                                    onIndexChanged(newIndex);
                                    setBtnStates(newIndex);
                                } else {
                                    flRef.current?.scrollToIndex({
                                        index: newIndex,
                                        animated: true,
                                    });
                                }
                            }}
                            t={t}
                            checkInternetConnection={false}>
                            <View
                                style={{
                                    flex: 1,
                                    alignSelf: "center",
                                    justifyContent: isLandscape
                                        ? "center"
                                        : "flex-end",
                                }}>
                                <WnaButtonIconInnerIcon
                                    iconName={"arrow-left"}
                                    currentAppStyle={currentAppStyle}
                                    currentAppTheme={currentAppTheme}
                                />
                            </View>
                        </WnaPressable>
                    ) : null}

                    {/* first button */}
                    {isFirstBtnVisible ? (
                        <WnaPressable
                            toolTip={t(i18nKeys.actionNextEntry)}
                            style={
                                isLandscape
                                    ? styles.btnPrevLandscape
                                    : styles.btnPrevPortrait
                            }
                            onPress={() => {
                                onCancel();
                                onFirst();
                            }}
                            t={t}
                            checkInternetConnection={false}>
                            <View
                                style={{
                                    flex: 1,
                                    alignSelf: "center",
                                    justifyContent: isLandscape
                                        ? "center"
                                        : "flex-end",
                                }}>
                                <WnaButtonIconInnerIcon
                                    iconName={"arrow-circle-left"}
                                    currentAppStyle={currentAppStyle}
                                    currentAppTheme={currentAppTheme}
                                />
                            </View>
                        </WnaPressable>
                    ) : null}

                    {/* next button */}
                    {isNextBtnVisible ? (
                        <WnaPressable
                            toolTip=""
                            style={
                                isLandscape
                                    ? styles.btnNextLandscape
                                    : styles.btnNextPortrait
                            }
                            onPress={() => {
                                if (
                                    currentIndexRef.current >=
                                    images.length - 1
                                )
                                    return;

                                const newIndex = currentIndexRef.current + 1;
                                if (Platform.OS == "web") {
                                    setCurrentIndex(newIndex);
                                    currentIndexRef.current = newIndex;
                                    onIndexChanged(newIndex);
                                    setBtnStates(newIndex);
                                } else {
                                    flRef.current?.scrollToIndex({
                                        index: newIndex,
                                        animated: true,
                                    });
                                }
                            }}
                            t={t}
                            checkInternetConnection={false}>
                            <View
                                style={{
                                    flex: 1,
                                    alignSelf: "center",
                                    justifyContent: isLandscape
                                        ? "center"
                                        : "flex-end",
                                }}>
                                <WnaButtonIconInnerIcon
                                    iconName={"arrow-right"}
                                    currentAppStyle={currentAppStyle}
                                    currentAppTheme={currentAppTheme}
                                />
                            </View>
                        </WnaPressable>
                    ) : null}

                    {/* last button */}
                    {isLastBtnVisible ? (
                        <WnaPressable
                            toolTip={t(i18nKeys.actionNextEntry)}
                            style={
                                isLandscape
                                    ? styles.btnNextLandscape
                                    : styles.btnNextPortrait
                            }
                            onPress={() => {
                                onCancel();
                                onLast();
                            }}
                            t={t}
                            checkInternetConnection={false}>
                            <View
                                style={{
                                    flex: 1,
                                    alignSelf: "center",
                                    justifyContent: isLandscape
                                        ? "center"
                                        : "flex-end",
                                }}>
                                <WnaButtonIconInnerIcon
                                    iconName={"arrow-circle-right"}
                                    currentAppStyle={currentAppStyle}
                                    currentAppTheme={currentAppTheme}
                                />
                            </View>
                        </WnaPressable>
                    ) : null}

                    {/* close button */}
                    {isScrollEnabled ? (
                        <WnaPressable
                            toolTip=""
                            style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                width: _navBtnWidth,
                                height: _navBtnHeight,
                                flex: 1,
                                alignContent: "stretch",
                                alignItems: "stretch",
                                backgroundColor: _navBtnBgColor,
                            }}
                            onPress={() => {
                                onCancel();
                            }}
                            t={t}
                            checkInternetConnection={false}>
                            <View
                                style={{
                                    flex: 1,
                                    alignSelf: "center",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                }}>
                                <WnaButtonIconInnerIcon
                                    iconName={"times"}
                                    currentAppStyle={currentAppStyle}
                                    currentAppTheme={currentAppTheme}
                                />
                            </View>
                        </WnaPressable>
                    ) : (
                        <WnaPressable
                            toolTip=""
                            style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                width: _navBtnWidth,
                                height: _navBtnHeight,
                                flex: 1,
                                alignContent: "stretch",
                                alignItems: "stretch",
                                backgroundColor: _navBtnBgColor,
                            }}
                            onPress={async () => {
                                await zoomableRefs[
                                    currentIndexRef.current
                                ].current?.zoomTo(1);
                                setZoomLevel(currentIndexRef.current, 1);
                            }}
                            t={t}
                            checkInternetConnection={false}>
                            <View
                                style={{
                                    flex: 1,
                                    alignSelf: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}>
                                <WnaButtonIconInnerIcon
                                    iconName={"expand"}
                                    currentAppStyle={currentAppStyle}
                                    currentAppTheme={currentAppTheme}
                                />
                            </View>
                        </WnaPressable>
                    )}
                </>
            )}
        </View>
    );
};
export default WnaImageViewerModal;

const styles = StyleSheet.create({
    btnNextLandscape: {
        position: "absolute",
        top: _navBtnHeight,
        bottom: _navBtnHeight,
        right: 0,
        width: _navBtnWidth,
        flex: 1,
        alignContent: "stretch",
        alignItems: "stretch",
        backgroundColor: _navBtnBgColor,
    },
    btnPrevLandscape: {
        position: "absolute",
        top: _navBtnHeight,
        bottom: _navBtnHeight,
        left: 0,
        width: _navBtnWidth,
        flex: 1,
        alignContent: "stretch",
        alignItems: "stretch",
        backgroundColor: _navBtnBgColor,
    },
    btnNextPortrait: {
        position: "absolute",
        height: _navBtnHeight,
        bottom: _navBtnHeight,
        right: 0,
        width: _navBtnWidth,
        flex: 1,
        alignContent: "stretch",
        alignItems: "stretch",
        backgroundColor: _navBtnBgColor,
    },
    btnPrevPortrait: {
        position: "absolute",
        height: _navBtnHeight,
        bottom: _navBtnHeight,
        left: 0,
        width: _navBtnWidth,
        flex: 1,
        alignContent: "stretch",
        alignItems: "stretch",
        backgroundColor: _navBtnBgColor,
    },
    imgContainer: {
        flexDirection: "row",
    },
});
