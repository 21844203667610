import { WnaAppContext } from "@app/WnaAppContext";
import WnaToastProvider from "@app/toast/WnaToastProvider";
import { WnaMapsGraphData } from "@domain/entities/WnaMapsGraphData";
import WnaRoute from "@domain/entities/WnaRoute";
import { FontAwesome5 } from "@expo/vector-icons";
import WnaRouteDao from "@infrastructure/dao/WnaRouteDao";
import { i18nKeys } from "@infrastructure/i18n/i18nKeys";
import { pickGeoFileAsync } from "@infrastructure/services/geodata/WnaGeoFilePicker";
import { getGraphDataByGeoJsonAsync } from "@infrastructure/services/geodata/WnaMapsGraphDataService";
import WnaAsyncFileCacheProvider from "@infrastructure/services/storage/WnaAsyncFileCacheProvider";
import WnaPressable from "@ui/components/buttons/WnaPressable";
import { WnaDataSourceItem } from "@ui/components/misc/WnaPicker";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { useModal } from "react-native-modalfy";
import WnaLogger from "wna-logger";
import WnaMapsGoogle from "./WnaMapsGoogle";
import WnaMapsInfos from "./WnaMapsInfos";

export type WnaMapsProps = {
    wnaRoute: WnaRoute;
    onRouteSelectedAsync: (
        selectedRoute: WnaRoute,
        graphData: WnaMapsGraphData
    ) => {};
    isEditMode: boolean;
};

const mapsInfoWidth = 512;
const mapsInfoBtnSize = 64;
const WnaMaps: React.FC<WnaMapsProps> = (props) => {
    const {
        currentAppTheme,
        currentAppStyle,
        appSettings,
        currentWindowWidth,
        isLandscape,
    } = useContext(WnaAppContext);

    if (appSettings == null) return null;

    // @ts-ignore
    const [currentRoute, setCurrentRoute] = useState<WnaRoute>(new WnaRoute());
    const { openModal } = useModal();

    const currentGraphDataRef = useRef(new WnaMapsGraphData());
    const [isEditMode, setIsEditMode] = useState(false);
    const isLandscapeInternal = currentWindowWidth > mapsInfoWidth * 1.5;

    const routesDsRawRef = useRef<Array<WnaRoute>>([]);
    const [routesDs, setRoutesDs] = useState<Array<WnaDataSourceItem>>([]);
    const { t } = useTranslation(["common"]);

    const loadRoutesDataSourceAsync = async () => {
        WnaLogger.start(WnaMaps.name, loadRoutesDataSourceAsync.name);
        try {
            if (routesDsRawRef.current.length > 0) return;

            // routesDsRawRef.current = await WnaRouteDao.readAllAsync(false);
            routesDsRawRef.current = await WnaRouteDao.readAllAsync(true);
            setRoutesDs(
                routesDsRawRef.current.map((item) => {
                    return {
                        value: item.identifier,
                        label: item.designator,
                    } as WnaDataSourceItem;
                })
            );
        } catch (error) {
            WnaLogger.error(
                WnaMaps.name,
                loadRoutesDataSourceAsync.name,
                error
            );
        } finally {
            WnaLogger.end(WnaMaps.name, loadRoutesDataSourceAsync.name);
        }
    };

    const onSelectRouteFromDropDownAsync = async (selectedRouteValue: any) => {
        try {
            WnaLogger.start(WnaMaps.name, onSelectRouteFromDropDownAsync.name);
            WnaLogger.warn(
                `try to get route (selectedRouteValue): ${selectedRouteValue} ...`
            );

            WnaLogger.warn(
                `... from routesDsRawRef: ${routesDsRawRef.current}`
            );
            const wnaRoute = routesDsRawRef.current.find(
                (x) => x.identifier === selectedRouteValue
            );

            WnaLogger.warn(`wnaRoute: ${wnaRoute}`);

            if (wnaRoute == undefined || wnaRoute.geoJsonUrl == "") {
                WnaLogger.warn(
                    `wnaRoute: ${wnaRoute} || geoJsonUrl: ${wnaRoute?.geoJsonUrl} - will cancel onSelectRouteFromDropDownAsync`
                );
                return;
            }

            const geoJson =
                await WnaAsyncFileCacheProvider.getCachedTextByUrlAsync(
                    wnaRoute.geoJsonUrl,
                    "geojson"
                );
            if (geoJson == "") {
                WnaLogger.warn(
                    `geoJson: ${geoJson} - will cancel onSelectRouteFromDropDownAsync`
                );
                return;
            }

            const graphData = await getGraphDataByGeoJsonAsync(geoJson, 20);
            props.onRouteSelectedAsync(wnaRoute, graphData);
        } catch (error) {
            WnaLogger.error(
                WnaMaps.name,
                onSelectRouteFromDropDownAsync.name,
                error
            );
        } finally {
            WnaLogger.end(WnaMaps.name, onSelectRouteFromDropDownAsync.name);
        }
    };

    const onSelectGeoDataFileAsync = async () => {
        try {
            WnaLogger.start(WnaMaps.name, onSelectGeoDataFileAsync.name);
            const graphData = await pickGeoFileAsync((errorMessage) =>
                WnaToastProvider.showError(errorMessage)
            );
            if (graphData.routePoints.length < 1) return;

            if (graphData.routeHash == "") return;

            //   dirtyGraphDataRef.current = graphData;
            const route = new WnaRoute({
                identifier: graphData.routeHash,
                designator: graphData.routeName,
                distance: graphData.infoDistance,
                comment: graphData.infoDistanceText,
                geoJson: graphData.geoJson,
                geoJsonHash: graphData.routeHash,
            });

            props.onRouteSelectedAsync(route, graphData);
        } catch (error) {
            WnaLogger.error(WnaMaps.name, onSelectGeoDataFileAsync.name, error);
        } finally {
            WnaLogger.end(WnaMaps.name, onSelectGeoDataFileAsync.name);
        }
    };

    useEffect(() => {
        WnaLogger.start(WnaMaps.name, useEffect.name);
        setIsEditMode(props.isEditMode);
        if (
            props.wnaRoute.identifier != "" &&
            (props.wnaRoute.identifier != currentRoute.identifier ||
                props.wnaRoute.geoJsonHash != currentRoute.geoJsonHash)
        )
            loadRouteAsync(props.wnaRoute);
        else if (
            props.wnaRoute.identifier != currentRoute.identifier ||
            props.wnaRoute.geoJsonHash != currentRoute.geoJsonHash
        )
            loadDefault();

        loadRoutesDataSourceAsync();
        WnaLogger.end(WnaMaps.name, useEffect.name);
    }, [props, isLandscape]);

    const loadDefault = () => {
        WnaLogger.start(WnaMaps.name, loadDefault.name);
        currentGraphDataRef.current = new WnaMapsGraphData();
        setCurrentRoute(new WnaRoute());
        WnaLogger.end(WnaMaps.name, loadDefault.name);
    };

    const loadRouteAsync = async (wnaRoute: WnaRoute) => {
        try {
            WnaLogger.start(
                WnaMaps.name,
                loadRouteAsync.name,
                wnaRoute.identifier
            );

            let geoJson = "";
            if (wnaRoute.geoJson !== "") {
                geoJson = wnaRoute.geoJson; // deprecated
            } else if (wnaRoute.geoJsonUrl !== "") {
                geoJson =
                    await WnaAsyncFileCacheProvider.getCachedTextByUrlAsync(
                        wnaRoute.geoJsonUrl,
                        "geojson"
                    );
            }

            currentGraphDataRef.current = await getGraphDataByGeoJsonAsync(
                geoJson,
                20
            );
            setCurrentRoute(wnaRoute);
        } catch (error) {
            WnaLogger.error(WnaMaps.name, loadRouteAsync.name, error);
        } finally {
            WnaLogger.end(
                WnaMaps.name,
                loadRouteAsync.name,
                wnaRoute.identifier
            );
        }
    };

    return (
        <View style={{ flex: 1, flexDirection: "row" }}>
            <View
                style={{
                    flex: 1,
                    backgroundColor: currentAppTheme.colors.white,
                }}>
                {!isLandscapeInternal ? (
                    <View
                        style={{
                            height: mapsInfoBtnSize,
                            backgroundColor: currentAppTheme.colors.coolgray1,
                        }}>
                        <WnaPressable
                            style={{
                                alignSelf: "stretch",
                                height: mapsInfoBtnSize,
                            }}
                            rippleWhite
                            onPress={() => {
                                openModal("WnaMapInfoModal", {
                                    graphData: currentGraphDataRef.current,
                                    paneWidth: mapsInfoWidth,
                                    isEditMode: isEditMode,
                                    routes: routesDs,
                                    onSelectGeoDataFileAsync:
                                        onSelectGeoDataFileAsync,
                                    onSelectRouteFromDropDownAsync:
                                        onSelectRouteFromDropDownAsync,
                                });
                            }}
                            t={t}
                            checkInternetConnection={false}>
                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "center",
                                }}>
                                <View
                                    style={{
                                        flex: 1,
                                        flexDirection: "row",
                                        alignItems: "center",
                                        paddingHorizontal: 8,
                                    }}>
                                    <Text
                                        numberOfLines={2}
                                        textBreakStrategy="highQuality"
                                        lineBreakMode="tail"
                                        style={currentAppStyle.textSmall}>
                                        {currentGraphDataRef.current
                                            .routeName == ""
                                            ? t(i18nKeys.infoNoRoute)
                                            : currentGraphDataRef.current
                                                  .routeName}
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        alignSelf: "flex-end",
                                        justifyContent: "center",
                                    }}>
                                    <View
                                        style={{
                                            height: mapsInfoBtnSize,
                                            width: mapsInfoBtnSize,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}>
                                        {isEditMode ? (
                                            <FontAwesome5
                                                name={"pen"}
                                                size={24}
                                                color={
                                                    currentAppTheme.colors.black
                                                }
                                            />
                                        ) : (
                                            <FontAwesome5
                                                name={"info-circle"}
                                                size={24}
                                                color={
                                                    currentAppTheme.colors.black
                                                }
                                            />
                                        )}
                                    </View>
                                </View>
                            </View>
                        </WnaPressable>
                    </View>
                ) : null}
                <WnaMapsGoogle graphData={currentGraphDataRef.current} />
            </View>
            {isLandscapeInternal ? (
                <View
                    style={{
                        width: mapsInfoWidth,
                    }}>
                    <ScrollView overScrollMode="auto">
                        <WnaMapsInfos
                            graphData={currentGraphDataRef.current}
                            paneWidth={mapsInfoWidth}
                            isEditMode={isEditMode}
                            routes={routesDs}
                            onSelectGeoDataFileAsync={onSelectGeoDataFileAsync}
                            onSelectRouteFromDropDownAsync={
                                onSelectRouteFromDropDownAsync
                            }
                        />
                    </ScrollView>
                </View>
            ) : null}
        </View>
    );
};
export default WnaMaps;
