import WnaUser from "@domain/entities/WnaUser";
import fetchWithTimeout from "@infrastructure/services/WnaFetchWithTimeoutProvider";
import { initPdfRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";
import GetPdfResponse from "@infrastructure/wnaApi/diaryGenerator/queries/GetPdfResponse";

/**
 * returns pdf of diary
 * @param wnaUser
 * @param chapterIdentifier
 */
const getPdfAsync = async (wnaUser: WnaUser, chapterIdentifier: string) =>
    getPdfByUrlAsync(
        `${WnaApiSettings.apiBaseUrl}/users/${wnaUser.id}/chapters/${chapterIdentifier}/pdf`
    );

const getPdfByUrlAsync = async (url: string) => {
    const timeOut = 1200000; // 20 min
    const request = initPdfRequest();
    const response = await handleResponseAsync(
        await fetchWithTimeout(url, request, {
            timeout: timeOut,
            abortHandler: (err) => {
                return new Response(new Blob());
            },
        })
    );
    const data = await response.blob();
    return {
        httpStatusCode: response.status,
        data: data,
    } as GetPdfResponse;
};

export { getPdfAsync, getPdfByUrlAsync };
