import WnaChapter from "@domain/entities/WnaChapter";
import WnaDiaryEntry from "@domain/entities/WnaDiaryEntry";
import WnaUser from "@domain/entities/WnaUser";
import WnaChapterDao from "@infrastructure/dao/WnaChapterDao";
import {
    isGreaterThan,
    isSmallerThan,
} from "@infrastructure/services/WnaDateTimeService";
import WnaAsyncStorageProvider from "@infrastructure/services/storage/WnaAsyncStorageProvider/WnaAsyncStorageProvider";
import createDiaryEntryAsync from "@infrastructure/wnaApi/diaryEntry/commands/CreateDiaryEntry";
import deleteDiaryEntryAsync from "@infrastructure/wnaApi/diaryEntry/commands/DeleteDiaryEntry";
import deleteDiaryEntryImageAsync from "@infrastructure/wnaApi/diaryEntry/commands/DeleteDiaryEntryImage";
import updateDiaryEntryAsync from "@infrastructure/wnaApi/diaryEntry/commands/UpdateDiaryEntry";
import uploadDiaryEntryImageAsync from "@infrastructure/wnaApi/diaryEntry/commands/UploadDiaryEntryImage";
import getAllDiaryEntriesByChapterAsync from "@infrastructure/wnaApi/diaryEntry/queries/GetAllDiaryEntriesByChapter";
import isAliveAsync from "@infrastructure/wnaApi/healthcheck/IsAliveAsync";
import WnaLogger from "wna-logger";

const _col = "diaryentries";
const _useDb = true;
const _encrypted = true;

/**
 * Dao for Diary entries
 */
export default class WnaDiaryEntryDao {
    private static _isSynced: boolean = false;

    public static resetIsSynced() {
        WnaDiaryEntryDao._isSynced = false;
    }

    public static getPath(
        wnaUser: WnaUser,
        chapter: WnaChapter,
        itemIdentifier: string
    ) {
        if (itemIdentifier == "") return "";

        return (
            wnaUser.id +
            "/" +
            WnaChapterDao.Col +
            "/" +
            chapter.identifier +
            "/" +
            _col +
            "/" +
            itemIdentifier
        );
    }

    public static async createAsync(
        user: WnaUser,
        chapter: WnaChapter,
        item: WnaDiaryEntry
    ) {
        WnaLogger.start(
            WnaDiaryEntryDao.name,
            WnaDiaryEntryDao.createAsync.name
        );
        try {
            WnaDiaryEntryDao._isSynced = false;
            item = await createDiaryEntryAsync(user, chapter, item);
        } catch (error) {
            WnaLogger.error(
                WnaDiaryEntryDao.name,
                WnaDiaryEntryDao.createAsync.name,
                error
            );
        }
        WnaLogger.end(WnaDiaryEntryDao.name, WnaDiaryEntryDao.createAsync.name);
        return item;
    }

    public static async readAllByChapterAsync(
        user: WnaUser,
        chapter: WnaChapter,
        sortBy: 0 | 1,
        forceRefresh: boolean
    ) {
        WnaLogger.start(
            WnaDiaryEntryDao.name,
            WnaDiaryEntryDao.readAllByChapterAsync.name,
            user.id + " | forceRefresh: " + forceRefresh
        );
        let ret = new Array<WnaDiaryEntry>();
        try {
            if (await isAliveAsync()) {
                if (forceRefresh) WnaDiaryEntryDao.resetIsSynced();

                if (!forceRefresh && WnaDiaryEntryDao._isSynced) {
                    WnaLogger.info(
                        WnaDiaryEntryDao.name,
                        WnaDiaryEntryDao.readAllByChapterAsync.name,
                        "is synced - read from async storage"
                    );
                    const json =
                        (await WnaAsyncStorageProvider.getItemAsync(
                            _col + user.id + "_" + chapter.identifier,
                            _encrypted,
                            _useDb
                        )) ?? "";
                    ret = JSON.parse(json);
                } else {
                    WnaLogger.info(
                        WnaDiaryEntryDao.name,
                        WnaDiaryEntryDao.readAllByChapterAsync.name,
                        "is not synced - write to async storage"
                    );
                    ret = await getAllDiaryEntriesByChapterAsync(user, chapter);

                    // store to async storage
                    const json = JSON.stringify(ret);
                    await WnaAsyncStorageProvider.setItemAsync(
                        _col + user.id + "_" + chapter.identifier,
                        json,
                        _encrypted,
                        _useDb
                    );
                }

                WnaDiaryEntryDao._isSynced = true;
            } else {
                const json =
                    (await WnaAsyncStorageProvider.getItemAsync(
                        _col + user.id + "_" + chapter.identifier,
                        _encrypted,
                        _useDb
                    )) ?? "";
                ret = JSON.parse(json);
            }
        } catch (error) {
            WnaLogger.error(
                WnaDiaryEntryDao.name,
                WnaDiaryEntryDao.readAllByChapterAsync.name,
                error
            );
            WnaDiaryEntryDao._isSynced = false;
        }
        WnaLogger.end(
            WnaDiaryEntryDao.name,
            WnaDiaryEntryDao.readAllByChapterAsync.name,
            user.id + " | forceRefresh: " + forceRefresh
        );

        ret = ret.sort((a, b) => {
            const aDate = new Date(a.dateStart);
            const bDate = new Date(b.dateStart);
            return sortBy > 0
                ? isGreaterThan(aDate, bDate)
                : isSmallerThan(aDate, bDate);
        });

        return ret;
    }

    public static async updateAsync(
        user: WnaUser,
        chapter: WnaChapter,
        item: WnaDiaryEntry
    ) {
        WnaLogger.start(
            WnaDiaryEntryDao.name,
            WnaDiaryEntryDao.updateAsync.name
        );
        try {
            if (item.identifier === "") return null;

            WnaDiaryEntryDao._isSynced = false;
            item = await updateDiaryEntryAsync(user, chapter, item);
        } catch (error) {
            WnaLogger.error(
                WnaDiaryEntryDao.name,
                WnaDiaryEntryDao.updateAsync.name,
                error
            );
        }
        WnaLogger.end(WnaDiaryEntryDao.name, WnaDiaryEntryDao.updateAsync.name);
        return item;
    }

    public static async uploadImageAsync(
        user: WnaUser,
        chapter: WnaChapter,
        item: WnaDiaryEntry,
        fileName: string,
        isFavorite: boolean,
        file: Blob
    ) {
        WnaLogger.start(
            WnaDiaryEntryDao.name,
            WnaDiaryEntryDao.uploadImageAsync.name
        );
        try {
            if (item.identifier === "" || fileName === "") return null;

            item = await uploadDiaryEntryImageAsync(
                user,
                chapter,
                item.identifier,
                fileName,
                isFavorite,
                file
            );
        } catch (error) {
            WnaLogger.error(
                WnaDiaryEntryDao.name,
                WnaDiaryEntryDao.uploadImageAsync.name,
                error
            );
        }
        WnaLogger.end(
            WnaDiaryEntryDao.name,
            WnaDiaryEntryDao.uploadImageAsync.name
        );
        return item;
    }

    public static async deleteImageAsync(
        user: WnaUser,
        chapter: WnaChapter,
        item: WnaDiaryEntry,
        fileName: string
    ) {
        WnaLogger.start(
            WnaDiaryEntryDao.name,
            WnaDiaryEntryDao.deleteImageAsync.name
        );
        try {
            if (item.identifier === "" || fileName === "") return null;

            item = await deleteDiaryEntryImageAsync(
                user,
                chapter,
                item.identifier,
                fileName
            );
        } catch (error) {
            WnaLogger.error(
                WnaDiaryEntryDao.name,
                WnaDiaryEntryDao.deleteImageAsync.name,
                error
            );
        }
        WnaLogger.end(
            WnaDiaryEntryDao.name,
            WnaDiaryEntryDao.deleteImageAsync.name
        );
        return item;
    }

    public static async deleteAsync(
        user: WnaUser,
        chapter: WnaChapter,
        item: WnaDiaryEntry
    ) {
        WnaLogger.start(
            WnaDiaryEntryDao.name,
            WnaDiaryEntryDao.deleteAsync.name
        );
        try {
            if (item.identifier === "") {
                WnaLogger.warn("identifier is empty");
                return;
            }

            WnaDiaryEntryDao._isSynced = false;
            item = await deleteDiaryEntryAsync(user, chapter, item);
        } catch (error) {
            WnaLogger.error(
                WnaDiaryEntryDao.name,
                WnaDiaryEntryDao.deleteAsync.name,
                error
            );
        }
        WnaLogger.end(WnaDiaryEntryDao.name, WnaDiaryEntryDao.deleteAsync.name);
        return item;
    }
}
