import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import { WnaCatalogGraphQuality } from "@domain/constants/catalogs/WnaCatalogGraphQuality";
import { WnaCatalogGraphTolerance } from "@domain/constants/catalogs/WnaCatalogGraphTolerance";
import { WnaCatalogMapType } from "@domain/constants/catalogs/WnaCatalogMapType";
import { WnaCatalogYesNo } from "@domain/constants/catalogs/WnaCatalogYesNo";
import WnaCatalogItem from "@domain/contracts/types/WnaCatalogItem";
import WnaUserSettings from "@domain/entities/WnaUserSettings";
import WnaUserSettingsDao from "@infrastructure/dao/WnaUserSettingsDao";
import { i18nKeys } from "@infrastructure/i18n/i18nKeys";
import WnaNavigationList from "@ui/components/navigation/WnaNavigationList";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import WnaSettingsRadioButton from "@ui/components/settings/WnaSettingsRadioButton";
import { WnaMenuItem } from "@ui/types/WnaMenuItem";
import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import WnaLogger from "wna-logger";

const WnaSettingsMapScreen: React.FC<WnaStackScreenProps> = () => {
    const {
        currentAppStyle,
        currentAppTheme,
        currentUserSettings,
        setCurrentUserSettings,
        isLandscape,
    } = useContext(WnaAppContext);
    const currentUserSettingsRef = useRef(currentUserSettings);
    const { t } = useTranslation(["common"]);
    const items: WnaMenuItem[] = [];

    // map controls
    items.push(
        {
            screen: "",
            text: t(i18nKeys.settingsMapDefaultMap),
            icon: "map",
            rightIcon: "",
            type: "mapDefaultType",
            data: undefined,
        },
        {
            screen: "",
            text: t(i18nKeys.settingsMapControls),
            icon: "map",
            rightIcon: "",
            type: "mapDisableDefaultUI",
            data: undefined,
        },
        {
            screen: "",
            text: t(i18nKeys.settingsMapScaleElevationChart),
            icon: "arrows-alt-v",
            rightIcon: "",
            type: "mapGraphAutoScale",
            data: undefined,
        }
        //, {
        //     screen: "",
        //     text: "Höhenprofil-Qualität",
        //     icon: "arrows-alt-v",
        //     rightIcon: "",
        //     type: "mapGraphQuality",
        //     data: undefined,
        // }
        // , {
        //     screen: "",
        //     text: "Höhenprofil-Ungenauigkeit",
        //     icon: "arrows-alt-v",
        //     rightIcon: "",
        //     type: "mapGraphTolerance",
        //     data: undefined,
        // }
    );

    const onSelectMapDisableUi = async (currentItem: WnaCatalogItem) => {
        WnaLogger.start(WnaSettingsMapScreen.name, onSelectMapDisableUi.name);
        const newUserSettings = new WnaUserSettings(
            currentUserSettingsRef.current ?? new WnaUserSettings()
        );
        newUserSettings.mapDisableDefaultUI = currentItem.id == 0;
        setCurrentUserSettings(newUserSettings);
        currentUserSettingsRef.current = newUserSettings;
        await WnaUserSettingsDao.createOrUpdateAsync(newUserSettings);
        WnaLogger.end(WnaSettingsMapScreen.name, onSelectMapDisableUi.name);
    };

    const onSelectMapDefaultType = async (currentItem: WnaCatalogItem) => {
        WnaLogger.start(WnaSettingsMapScreen.name, onSelectMapDefaultType.name);
        const newUserSettings = new WnaUserSettings(
            currentUserSettingsRef.current ?? new WnaUserSettings()
        );
        newUserSettings.mapDefaultType = currentItem.id;
        setCurrentUserSettings(newUserSettings);
        currentUserSettingsRef.current = newUserSettings;
        await WnaUserSettingsDao.createOrUpdateAsync(newUserSettings);
        WnaLogger.end(WnaSettingsMapScreen.name, onSelectMapDefaultType.name);
    };

    const onSelectMapGraphQuality = async (currentItem: WnaCatalogItem) => {
        WnaLogger.start(
            WnaSettingsMapScreen.name,
            onSelectMapGraphQuality.name
        );
        const newUserSettings = new WnaUserSettings(
            currentUserSettingsRef.current ?? new WnaUserSettings()
        );
        newUserSettings.mapGraphQuality = currentItem.id;
        setCurrentUserSettings(newUserSettings);
        currentUserSettingsRef.current = newUserSettings;
        await WnaUserSettingsDao.createOrUpdateAsync(newUserSettings);
        WnaLogger.end(WnaSettingsMapScreen.name, onSelectMapGraphQuality.name);
    };

    const onSelectMapGraphTolerance = async (currentItem: WnaCatalogItem) => {
        WnaLogger.start(
            WnaSettingsMapScreen.name,
            onSelectMapGraphTolerance.name
        );
        const newUserSettings = new WnaUserSettings(
            currentUserSettingsRef.current ?? new WnaUserSettings()
        );
        newUserSettings.mapGraphTolerance = currentItem.id;
        setCurrentUserSettings(newUserSettings);
        currentUserSettingsRef.current = newUserSettings;
        await WnaUserSettingsDao.createOrUpdateAsync(newUserSettings);
        WnaLogger.end(
            WnaSettingsMapScreen.name,
            onSelectMapGraphTolerance.name
        );
    };

    const onSelectMapGraphAutoScale = async (currentItem: WnaCatalogItem) => {
        WnaLogger.start(
            WnaSettingsMapScreen.name,
            onSelectMapGraphAutoScale.name
        );
        const newUserSettings = new WnaUserSettings(
            currentUserSettingsRef.current ?? new WnaUserSettings()
        );
        newUserSettings.mapGraphAutoScale = currentItem.id;
        setCurrentUserSettings(newUserSettings);
        currentUserSettingsRef.current = newUserSettings;
        await WnaUserSettingsDao.createOrUpdateAsync(newUserSettings);
        WnaLogger.end(
            WnaSettingsMapScreen.name,
            onSelectMapGraphAutoScale.name
        );
    };

    const renderItem = (currentItem: WnaMenuItem) => {
        switch (currentItem.type) {
            case "mapDisableDefaultUI":
                return (
                    <WnaSettingsRadioButton
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogYesNo}
                        selectedId={
                            currentUserSettings?.mapDisableDefaultUI == true
                                ? 0
                                : 1
                        }
                        isEditMode={true}
                        onSelect={onSelectMapDisableUi}
                    />
                );
            case "mapDefaultType":
                return (
                    <WnaSettingsRadioButton
                        horizontal={isLandscape}
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogMapType}
                        selectedId={currentUserSettings?.mapDefaultType ?? 0}
                        isEditMode={true}
                        onSelect={onSelectMapDefaultType}
                    />
                );
            case "mapGraphQuality":
                return (
                    <WnaSettingsRadioButton
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogGraphQuality}
                        selectedId={currentUserSettings?.mapGraphQuality ?? 1}
                        isEditMode={true}
                        onSelect={onSelectMapGraphQuality}
                    />
                );
            case "mapGraphTolerance":
                return (
                    <WnaSettingsRadioButton
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogGraphTolerance}
                        selectedId={currentUserSettings?.mapGraphTolerance ?? 0}
                        isEditMode={true}
                        onSelect={onSelectMapGraphTolerance}
                    />
                );
            case "mapGraphAutoScale":
                return (
                    <WnaSettingsRadioButton
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogYesNo}
                        selectedId={currentUserSettings?.mapGraphAutoScale ?? 0}
                        isEditMode={true}
                        onSelect={onSelectMapGraphAutoScale}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <WnaBaseScreenWithInfo>
            <WnaNavigationList items={items} renderItem={renderItem} />
        </WnaBaseScreenWithInfo>
    );
};

export default WnaSettingsMapScreen;
