import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import WnaAppSettingsDao from "@infrastructure/dao/WnaAppSettingsDao";
import { i18nKeys } from "@infrastructure/i18n/i18nKeys";
import WnaButtonText from "@ui/components/buttons/WnaButtonText";
import WnaImage from "@ui/components/images/WnaImage";
import { TFunction } from "i18next";
import { Component } from "react";
import { Text, View } from "react-native";

export type WnaAppointmentListHeaderProps = {
    currentAppStyle: WnaAppStyle;
    currentAppTheme: WnaTheme;
    navigateToAccommodationScreen: () => void;
    t: TFunction<string[], undefined>;
};

class WnaAppointmentListHeader extends Component<WnaAppointmentListHeaderProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaAppointmentListHeaderProps>
    ): boolean {
        return (
            nextProps.currentAppTheme.dark != this.props.currentAppTheme.dark
        );
    }

    render() {
        return (
            <View
                style={[
                    this.props.currentAppStyle.containerCenterMaxWidth,
                    {
                        backgroundColor:
                            this.props.currentAppTheme.colors.white,
                        // padding: 16,
                        gap: 16,
                        // marginLeft: 16, // bug in flashlist
                        // marginBottom: 8
                    },
                ]}>
                <WnaImage
                    currentAppTheme={this.props.currentAppTheme}
                    imageUrl={WnaAppSettingsDao.getImageResourceUrl(
                        "ph_007.jpg"
                    )}
                    style={{
                        height: 240,
                        maxHeight: 240,
                        backgroundColor:
                            this.props.currentAppTheme.colors.coolgray2,
                    }}
                />

                <Text style={[this.props.currentAppStyle.textTitleLarge]}>
                    {this.props.t(i18nKeys.screenSubtitleAppointmentList)}
                </Text>

                <WnaButtonText
                    currentAppTheme={this.props.currentAppTheme}
                    currentAppStyle={this.props.currentAppStyle}
                    text={this.props.t(
                        i18nKeys.screenTitleAccommodationDetails
                    )}
                    onPress={this.props.navigateToAccommodationScreen}
                    t={this.props.t}
                    checkInternetConnection={false}
                />
            </View>
        );
    }
}

export default WnaAppointmentListHeader;
