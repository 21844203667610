import WnaAppSettings from "@domain/entities/WnaAppSettings";
import fetchWithTimeout from "@infrastructure/services/WnaFetchWithTimeoutProvider";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";

const getAppSettingsAsync = async () => {
    const url = `${WnaApiSettings.apiBaseUrl}/appsettings`;
    const request = initJsonRequest("GET");
    const response = await handleResponseAsync(
        await fetchWithTimeout(url, request, {
            timeout: 10000,
            abortHandler: (err) => {
                return new Response(JSON.stringify({} as WnaAppSettings));
            },
        })
    );
    return (await response.json()) as WnaAppSettings;
};

export default getAppSettingsAsync;
