import WnaCatalogItem from "@domain/contracts/types/WnaCatalogItem";
import { i18nKeys } from "@infrastructure/i18n/i18nKeys";
import { WnaStaticColors } from "@infrastructure/services/WnaThemeService";

export const WnaCatalogAppTheme: Array<WnaCatalogItem> = [
    {
        id: 0,
        text: i18nKeys.catalogThemeSystem,
        displayColor: "transparent",
        selectionColor: WnaStaticColors.staticCoolgray6,
    },
    {
        id: 1,
        text: i18nKeys.catalogThemeLight,
        displayColor: "transparent",
        selectionColor: WnaStaticColors.staticCoolgray6,
    },
    {
        id: 2,
        text: i18nKeys.catalogThemeDark,
        displayColor: "transparent",
        selectionColor: WnaStaticColors.staticCoolgray6,
    },
];
